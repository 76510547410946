import React, { useCallback, useEffect, useMemo, useState } from 'react';
import axios from '../../utils/axios';
import { useTranslation } from 'react-i18next';
import { Box, Button, CircularProgress, Container, Skeleton, useMediaQuery } from '@mui/material';
import { FormsResponse, FreeTextQuestionItem, ImageItem, QuestionItem, RatingQuestionItem } from './interface';
import FreeText from './components/FreeText';
import RatingQuestion from './components/RatingQuestion';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { getCurrentLanguageFromLocalStorage, translate } from '../../utils/languageTranslation.util';
import GoogleReviews from './components/GoogleReviews';
import './ReturnStyles.scss';
import ImageUpload from './components/ImageUpload';
import { ICustomField, ICustomFieldComponentModel, ICustomFieldData, PinCodeResponse, Product, Site } from '../product/interfaces';
import { generateCustomFieldControl, generateCustomFieldValidationSchema } from '../customFields/customFIeldControlFactory';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import axiosInstance from '../../utils/axios';
import { BookingItem, LockerItem } from '../unlock/interface';
import { ProductsList } from '../booking/productDisplayCard/ProductsList';

interface Props {
    booking: BookingItem;
    siteId: string;
    pinCodesItems: PinCodeResponse[];
    siteInformation: Site | null;
    lockerItems: LockerItem[];
}

export default function ReturnPage({ booking, siteId, pinCodesItems, siteInformation, lockerItems }: Props) {
    const [isSendAnswersLoading, setIsSendAnswersLoading] = useState(false);
    const [isQuestionsLoading, setIsQuestionsLoading] = useState(true);
    const [isReturned, setIsReturned] = useState(false);
    const [formData, setFormData] = useState<FormsResponse | null>(null);
    const [noQuestions, setNoQuestions] = useState(false);
    const { t } = useTranslation();
    const isMobile = useMediaQuery('(max-width:600px)');
    const [useCustomFields, setUseCustomFields] = useState(false);
    const [products, setProducts] = useState<Product[]>([]);

    const getAllProducts = (siteId) => {
        return axios.get(`product/v1/allProducts/${siteId}`);
    };


    async function getFormData() {
        try {
            const response = await axios.get<FormsResponse>(`sites/v1/questionnaire/${siteId}/${booking.id}`);

            if (response.status === 200) {
                const data = await response.data;
                setFormData(data);
                if (data.submitted) {
                    setIsReturned(true);
                }

                if (data.questions.length === 0) {
                    setNoQuestions(true);
                } else {
                    setIsQuestionsLoading(false);
                }
            }
        } catch (error) {
            console.error("Couldn't get form data");
        }
    }

    async function returnOrder() {
        try {
            const response = await axios.get(`hardware/locker/return/${booking.id}`);
            if (response.status === 200) {
                setIsReturned(true);
                setIsQuestionsLoading(false);
            } else {
                console.error('Something went wrong');
            }
        } catch (error) {
            console.error('Something went wrong');
        }
    }

    async function sendAnswers() {
        if (noQuestions) return returnOrder();

        try {
            const objecToSend = {
                questionsAndAnswers: formData?.questions
            };

            const response = await axios.post(`booking/v1/review/${booking.id}`, objecToSend);
            if (response.status === 200) {
                returnOrder();
            }
        } catch (error) {
            setIsSendAnswersLoading(false);
            console.error("Couldn't send answers");
        }
    }

    useEffect(() => {
        if (siteId) {
            getFormData();
        }
        if (siteInformation?.returnPageSettings?.customFields?.length) {
            setUseCustomFields(true);
            setIsQuestionsLoading(false);
        }
        if (siteId) {
            getAllProducts(siteId).then((response) => {
                setProducts(response.data);
            });

        }

        if (booking.pickUpState === 'Returned') {
            setIsReturned(true);
            setIsQuestionsLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [siteId]);

    const formSchema = generateCustomFieldValidationSchema(siteInformation?.returnPageSettings?.customFields, t);

    const {
        handleSubmit,
        control,
        register,
        getValues,
        watch,
        setValue,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(formSchema),
        mode: 'all'
    });

    const handleCustomFieldChange = (field, newValue) => {
        setValue(field.id, newValue);
    };

    const customFields: ICustomFieldComponentModel[] = useMemo(() => {
        if (siteInformation?.returnPageSettings?.customFields) {
            let customFields = siteInformation?.returnPageSettings?.customFields
                .map((field: ICustomField): ICustomFieldComponentModel => {
                    return ({
                        id: field.id,
                        name: field.name[getCurrentLanguageFromLocalStorage() || 'en'] ?? field.name['sv'],
                        order: 999,
                        required: field.isMandatory,
                        active: field.isActive,
                        customControl: generateCustomFieldControl(field, control, handleCustomFieldChange, errors)
                    });
                });

            return customFields;
        }

        return [];
    }, [siteInformation, errors]);

    const getQuestionComponent = (question: QuestionItem, index: number) => {
        switch (question.type) {
            case 'freeText':
                return (
                    <FreeText
                        question={question}
                        index={index}
                        updateQuestion={(index: number, answer: string) => {
                            if (formData && formData.questions) {
                                const newFormData = { ...formData };
                                const question = newFormData.questions[index] as FreeTextQuestionItem;
                                question.answer = answer;
                                setFormData(newFormData);
                            }
                        }}
                    />
                );
            case 'rating':
                return (
                    <RatingQuestion
                        question={question}
                        index={index}
                        updateQuestion={(index: number, answer: number) => {
                            if (formData && formData.questions) {
                                const newFormData = { ...formData };
                                const question = newFormData.questions[index] as RatingQuestionItem;
                                question.answer = answer;
                                setFormData(newFormData);
                            }
                        }}
                    />
                );
            case 'photo':
                return (
                    <ImageUpload
                        question={question}
                        bookingId={booking.id}
                        updateQuestion={(answer: string) => {
                            if (formData && formData.questions) {
                                const newFormData = { ...formData };
                                const question = newFormData.questions[index] as ImageItem;
                                question.answer = answer;
                                setFormData(newFormData);
                            }
                        }}
                    />
                );
            default:
                return null;
        }
    };

    const isAnyQuestionAnswerInvalid = useMemo(() => {
        if (formData?.questions) {
            return formData.questions.some((question) => {
                if (question.mandatory && !question.answer) {
                    return true;
                }
                return false;
            });
        }

        return false;
    }, [formData]);

    useEffect(() => {
        if (typeof window.gtag === 'function') {
            window.gtag('event', 'page_view', {
                page_title: 'Return Page'
            });
        }
    }, []);

    const onSaveCustomFieldData = useCallback(async () => {
        setIsSendAnswersLoading(true);
        let data: ICustomFieldData[] = [];
        siteInformation?.returnPageSettings?.customFields?.forEach((field: ICustomField) => {
            let customFieldData: ICustomFieldData = {
                customFieldId: field.id,
                value: getValues(field.id),
                relatedToId: booking.id,
                customFieldKey: 'booking_return'
            }
            data.push(customFieldData);
        });

        const response = await axiosInstance.post(`customFields/v1/customfielddata`, data);

        if (response.status >= 200 && response.status < 300) {
            setIsSendAnswersLoading(true);
            returnOrder();
        }
        else {

        }
    }, [siteInformation, booking]);

    return (
        <Box className="returnPage-box">
            {siteInformation?.returnPageSettings?.showHeading !== false && (
                <h2>
                    {siteInformation?.returnPageSettings?.returnHeadings
                        ? siteInformation.returnPageSettings.returnHeadings[getCurrentLanguageFromLocalStorage()]
                        : t('translation.return.confirmReturn')}
                </h2>
            )}
            {siteInformation && siteInformation?.returnPageSettings?.showProducts !== false &&
                !isReturned &&
                <ProductsList
                    pinDataArray={pinCodesItems}
                    sectionData={siteInformation?.returnPageSettings!}
                    products={products}
                    bookingInfoProducts={booking.products}
                    lockerItems={lockerItems}
                    showImages={siteInformation.unlockDisplaySettings?.showImages}
                    showPinCodes={siteInformation?.returnPageSettings?.showPinCodes} />
            }
            <Box
                sx={{
                    padding: isMobile ? '16px' : '24px',
                    textAlign: 'left',
                    backgroundColor: '#F6F6F6',
                    borderRadius: '16px',
                    width: '100%'
                }}>
                {isQuestionsLoading && !noQuestions ? (
                    <>
                        <Skeleton variant="text" height={40} />
                        <Skeleton variant="text" height={40} />
                        <Skeleton variant="text" height={40} />
                        <Skeleton variant="text" height={40} />
                        <Skeleton variant="text" height={40} />
                        <Skeleton variant="text" height={40} />
                        <Skeleton variant="text" height={40} />
                        <Skeleton variant="text" height={40} />
                    </>
                ) : isReturned ? (
                    <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                        <CheckCircleIcon sx={{ color: '#00C853', fontSize: '40px' }} />
                        <h4>{t('translation.return.thankYouCompleted')}</h4>
                    </div>
                ) : (
                    useCustomFields ?
                        <>
                            <form>
                                {customFields
                                    .map((field) => {
                                        if (field.customControl) {
                                            return <Container className="container" key={field.id}>{field.customControl}</Container>;
                                        }
                                    })}
                                <div style={{ textAlign: 'center', marginTop: noQuestions ? '0px' : '24px' }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        sx={{ borderRadius: '20px' }}
                                        disabled={(isSendAnswersLoading)}
                                        onClick={handleSubmit(onSaveCustomFieldData)}>
                                        {t('translation.return.completeButton')}
                                        {isSendAnswersLoading && (
                                            <CircularProgress
                                                size={30}
                                                sx={{
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    marginTop: '-16px',
                                                    marginLeft: '-16px'
                                                }}
                                            />
                                        )}
                                    </Button>
                                </div>
                            </form>
                        </> :
                        <>
                            {formData?.questions.map((question, index) => {
                                return (
                                    <div key={index} style={{ marginBottom: '16px' }}>
                                        {getQuestionComponent(question, index)}
                                    </div>
                                );
                            })}

                            <div style={{ textAlign: 'center', marginTop: noQuestions ? '0px' : '24px' }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{ borderRadius: '20px' }}
                                    disabled={(isAnyQuestionAnswerInvalid || isSendAnswersLoading) && !noQuestions}
                                    onClick={() => {
                                        setIsSendAnswersLoading(true);
                                        sendAnswers();
                                    }}>
                                    {t('translation.return.completeButton')}
                                    {isSendAnswersLoading && (
                                        <CircularProgress
                                            size={30}
                                            sx={{
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                marginTop: '-16px',
                                                marginLeft: '-16px'
                                            }}
                                        />
                                    )}
                                </Button>
                            </div>
                        </>
                )}
            </Box>

            <div
                dangerouslySetInnerHTML={{
                    __html: translate(formData?.returnMessage ?? '', getCurrentLanguageFromLocalStorage())
                }}
                style={{ marginBottom: '24px', marginTop: '24px' }}></div>
            {formData?.placeId && <GoogleReviews placeId={formData?.placeId} reviewUrl={formData?.reviewLink} />}
        </Box>
    );
}
