import { Button, CircularProgress, FormControl, FormHelperText } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CameraAltRoundedIcon from '@mui/icons-material/CameraAltRounded';
import imageCompression from 'browser-image-compression';
import { ICustomField } from '../product/interfaces';
import { Control, Controller } from 'react-hook-form';
import axiosInstance from '../../utils/axios';
import { fetchTranslation, getCurrentLanguageFromLocalStorage, translate } from '../../utils/languageTranslation.util';
import { string } from 'yup';
import './css/customFieldUploadImage.scss';

interface CustomFieldRatingProps {
    customField: ICustomField;
    onChange: Function;
    control: Control;
    errors: any;
}

const CustomFieldImageUpload = ({ customField, onChange, control, errors }: CustomFieldRatingProps) => {
    const { t } = useTranslation();
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [base64Image, setBase64Image] = useState<string | null>(null);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [isImageLoading, setIsImageLoading] = useState(false);

    const uploadImageToSignedUrl = async (signedUrl: string, file: File) => {
        try {
            const response = await fetch(signedUrl, {
                method: 'PUT',
                headers: {
                    'Content-Type': file.type
                },
                body: file
            });

            if (!response.ok) {
                throw new Error('Failed to upload image');
            }
        } catch (error) {
            console.error('Error when uploading:', error);
            setSelectedFile(null);
            setBase64Image(null);
        }
    };

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file && file.type.startsWith('image/')) {
            try {
                setIsImageLoading(true);
                const options = {
                    maxSizeMB: 1,
                    maxWidthOrHeight: 1920,
                    useWebWorker: true
                };

                const compressedFile = await imageCompression(file, options);
                setSelectedFile(compressedFile);

                const compressedBase64 = await imageCompression.getDataUrlFromFile(compressedFile);
                setBase64Image(compressedBase64);

                const tuple = await getSignedUrl('custom-field', file.name);
                await uploadImageToSignedUrl(tuple.url, compressedFile);

                onChange(customField, tuple.filePath);

                console.log(tuple.url);
                console.log(tuple.filePath);

                return tuple.filePath;

            } catch (error) {
                console.error('Error during image compression or upload:', error);
            } finally {
                setIsImageLoading(false);
            }
        }
    };

    const getSignedUrl = async (originType: string, fileName: string): Promise<any> => {
        try {
            const response = await axiosInstance.get(`media/v1/getSignedUploadUrl/${customField.accountId}/${originType}/${fileName}`);
            if (response.status === 200) {
                return response.data;
            }
        } catch (err) {
            setBase64Image(null);
            setSelectedFile(null);
            console.error('Error when uploading:', err);
            return {};
        }

        return {};
    }

    return (
        <div className="img-upload-container">
            <div className="img-upload-container__left-column">
                <div style={{ marginBottom: '4px' }}>
                    {customField.isMandatory && <span style={{ color: 'red', marginRight: '4px', fontWeight: 600 }}>*</span>}
                    {fetchTranslation(customField.name)}
                </div>
                <Controller
                    name={customField.id}
                    control={control}
                    defaultValue=''
                    rules={{ required: customField.isMandatory }}
                    render={({ field }) => (
                        <FormControl
                            variant="outlined"
                            size="small"
                            error={Boolean(errors[customField.id])}
                            key={customField.id}>
                            <input
                                ref={inputRef}
                                type="file"
                                accept="image/*"
                                capture="environment"
                                onChange={async (e) => {
                                    const newValue = await handleFileChange(e);
                                    // Update react-hook-form with the new value if available
                                    if (newValue) {
                                        field.onChange(newValue);
                                    }
                                }}
                                style={{ display: 'none' }}
                            />
                            {errors[customField.id] && (
                                <FormHelperText error>
                                    {errors[customField.id]?.message}
                                </FormHelperText>
                            )}
                        </FormControl>
                    )}
                />
                <Button
                    variant="contained"
                    color='primary'
                    endIcon={<CameraAltRoundedIcon style={{ color: 'primary' }} />}
                    className="img-upload-container__button"
                    onClick={() => inputRef.current?.click()}
                    fullWidth>
                    {base64Image ? t('translation.beforeUnlock.changePicture') : t('translation.beforeUnlock.takeAPicture')}
                </Button>
            </div>
            {base64Image && selectedFile ? (
                <div style={{ position: 'relative', display: 'inline-block' }}>
                    <img src={base64Image} alt="Captured" style={{ maxWidth: '100%', display: 'block' }} />
                    {isImageLoading && (
                        <>
                            <div
                                style={{
                                    position: 'absolute',
                                    inset: 0,
                                    backgroundColor: 'rgba(128, 128, 128, 0.5)',
                                    zIndex: 1,
                                    borderRadius: '15px'
                                }}
                            />
                            <CircularProgress
                                size={30}
                                sx={{ position: 'absolute', top: '50%', left: '50%', marginTop: '-16px', marginLeft: '-16px', zIndex: 2 }}
                            />
                        </>
                    )}
                </div>
            ) : (
                <img src="/placeholder.png" alt="Upload" className="image-preview" />
            )}
        </div>
    );
};
export default CustomFieldImageUpload;
