import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { LanguageTextTypeV1Alpha1 } from '../../utils/languageTranslation.util';

export enum ProductTypes {
    RENTAL = 0,
    ACCESSORY = 1,
    INSURANCE = 2,
    EVENT = 3
}

export enum BookingPaymentProviders {
    BILLMATE = 0,
    MANUAL = 1
}

export enum BookingType {
    PRODUCTS = 1,
    EVENTS = 2,
    GIFTCARDS = 3
}

export enum PromoCodeType {
    PUNCHCARD = 0,
    GIFTCARD = 1,
    PROMOCODE = 2
}

export interface RentalProductPrices {
    hourPrices: number[];
    dayPrices: number[];
}

export interface Product {
    accessories: string[];
    accessoryPriceTable?: { highSeasonPrices: RentalProductPrices; lowSeasonPrices: RentalProductPrices };
    accountId: string;
    artNumber: string;
    availability: number;
    cost: number;
    created: Date;
    description: LanguageTextTypeV1Alpha1 | string;
    ean: string;
    forcingFee: boolean;
    id: string;
    insurancePriceTable?: { highSeasonPrices: RentalProductPrices; lowSeasonPrices: RentalProductPrices }
    inventory: number;
    name: LanguageTextTypeV1Alpha1 | string;
    order: number;
    productImageURL: string;
    price: number;
    rentalPriceTable: { highSeasonPrices: RentalProductPrices; lowSeasonPrices: RentalProductPrices };
    seasonStart: Date;
    seasonEnd: Date;
    // highSeasonPrice?: number;
    // lowSeasonPrice?: number;
    siteId: string;
    type: ProductTypes;
    updated: Date;
    vat: string;
    quantity: number;
}

// export interface Locker {
//   active: boolean;
//   bluetoothId: string;
//   id: string;
//   lockerNumber: number;
//   masterPIN: string;
//   openRequest: boolean;
//   pin: string;
//   productId: string;
//   siteId: string;
//   stationId: string;
//   type: string;
// }

export interface Locker {
    availabilityTimes: number[];
    lockerNumber: number | string;
    productDescription: LanguageTextTypeV1Alpha1 | string;
    productName: LanguageTextTypeV1Alpha1 | string;
    productId: string;
    lockerId: string;
}

// export interface Booking {
//   id?: string;
//   bookingCode?: string;
//   accountId: string;
//   totalCost: number;
//   totalVAT: number;
//   totalDiscount: number;
//   createdAt: Date;
//   customerId: string;
//   duration: Date;
//   promoCode: string;
//   siteId: string;
//   startDate: string;
//   endDate: string;
//   products: Product[];
//   channel: string;
//   paymentProvider: BookingPaymentProviders;
//   paymentType: BookingPaymentTypes;
//   paymentStatus: BookingPaymentStatusType;
//   status: BookingStatus;
// }

export interface BookingReceipt {
    id?: string;
    totalCost: number;
    totalDiscount: number;
    totalVAT: number;
    promoCode?: string;
    events?: EventV1[];
    startDate: string;
    endDate: string;
    createdAt: string;
    site: Site;
    customer: Customer;
    logoImageURL?: string;
    status: BookingStatus;
    products: Product[];
    pincodeData?: PinCodeResponse[];
    timeFormatPreference?: boolean;
}

export interface PinCodeResponse {
    lockerNumber: number | string;
    productName: string;
    pinCode: string;
    lockerType?: LockerTypeV1Alpha1;
}

export interface PinCodeResponseExtended extends PinCodeResponse {
    lockerId: string;
}

export interface BillmateInitialResponse {
    number: string;
    orderid: string;
    status: string;
    url: string;
}

export interface CreateSessionResponse {
    clientSecret: string;
    publicStripeKey: string;
    orderId: string;
    sessionExpiryTime: string;
    url?: string;
    openNow: boolean;
}

export interface Customer {
    id?: string;
    accountId?: string;
    firstName: string;
    lastName: string;
    email?: string;
    phoneNumber?: string;
    address?: string;
    zipCode?: string;
    city?: string;
    country?: string;
    consentToEmail: boolean;
    created?: string;
    updated?: string;
}

export interface OpenHour {
    // open: string;
    // close: string;
    time: string;
    enabled: boolean;
}

export interface TimesObject {
    hour: number;
    text: string;
    enabled: boolean;
    maxHoursEnable: boolean;
}

export interface SiteOpenHours {
    open: string;
    close: string;
}

export interface SeasonDates {
    seasonStart: string;
    seasonEnd: string;
    closedDates: string[];
    openHours: SiteOpenHours[];
}

export interface ValidatedCampaignCode {
    code: string;
    percentDiscount: number;
    valueDiscount: number;
}

export enum TimeSlotSettingV1 {
    HOUR = 'hour',
    HALF_HOUR = 'half_hour',
    QUARTER_HOUR = 'quarter_hour'
}

export interface Site {
    id?: string;
    name: string;
    account: Account;
    accountId: string;
    type: string;
    typeOfProducts: string[];
    rentalType: string;
    webbURL?: string;
    siteImageURL: string;
    amenities: string;
    shortText: LanguageTextTypeV1Alpha1 | string;
    seo?: LanguageTextTypeV1Alpha1 | string;
    municipality: string;
    region: string;
    address: string;
    zipCode: string;
    city: string;
    country: string;
    latitude: number;
    longitude: number;
    active: boolean;
    openStartDate: string;
    openEndDate: string;
    openHours: SiteOpenHours[];
    closedDates?: string[];
    highSeasonStartDate?: string;
    highSeasonEndDate?: string;
    lowSeasonStartDate?: string;
    lowSeasonEndDate?: string;
    textTOC: LanguageTextTypeV1Alpha1 | string;
    textBookingConrimationEmail: string;
    textThankyouPage: string;
    textPrintOrder: string;
    email: string;
    phone: string;
    created?: string;
    updated?: string;
    timePeriods: TimePeriod[];
    timezone: string;
    siteBookingConfiguration: SiteConfigurationV1Alpha1;
    languageToShowInBookingView: string[];
    timeSlotSetting: TimeSlotSettingV1;
    cartSettings?: CartSettingsV1Alpha1;
    qrBookingDisplaySettings?: QrBookingDisplaySettingsV1Alpha;
    qrTimeSelection?: number[];
    returnPageSettings?: ReturnPageSettingsV1Alpha;
    siteThemeColor?: string;
    unlockDisplaySettings?: UnlockSettingsV1Alpha;
    beforeBookingSettings: SiteBeforeBookingSettingsV1;
}

export interface SiteBeforeBookingSettingsV1 {
    isEnabled?: boolean;
    header: LanguageTextTypeV1Alpha1;
    customFields?: ICustomField[];
    showProducts: boolean;
    showPinCodes: boolean;
}

export interface CartSettingsV1Alpha1 {
    name: CartSettingV1Alpha1;
    surname: CartSettingV1Alpha1;
    email: CartSettingV1Alpha1;
    phone: CartSettingV1Alpha1;
    address: CartSettingV1Alpha1;
    zipCode: CartSettingV1Alpha1;
    city: CartSettingV1Alpha1;
    country: CartSettingV1Alpha1;
    customFields?: ICustomField[];
}

export interface CartSettingV1Alpha1 {
    active: boolean;
    mandatory: boolean;
    order?: number;
}

export interface QrBookingDisplaySettingsV1Alpha {
    showDates?: boolean;
    showQrBookingOptions?: boolean;
    showQrTitle?: boolean;
    customQrTitles?: LanguageTextTypeV1Alpha1;
}

export interface UnlockSettingsV1Alpha {
    showTimer?: boolean;
    showConfirmButton?: boolean;
    showHeading?: boolean;
    showMessage?: boolean;
    showImages?: boolean;
    unlockHeadings?: LanguageTextTypeV1Alpha1;
    unlockMessages?: LanguageTextTypeV1Alpha1;
}

export interface ReturnPageSettingsV1Alpha {
    showHeading?: boolean;
    showProducts?: boolean;
    showPinCodes?: boolean;
    returnHeadings?: LanguageTextTypeV1Alpha1;
    customFields?: ICustomField[];
}

export type SiteSectionSettings = ReturnPageSettingsV1Alpha | UnlockSettingsV1Alpha | SiteBeforeBookingSettingsV1

interface Account {
    measurementId?: string;
    currency: string;
    timeSettingAmPm: boolean;
}

export enum EventTypeV1 {
    DEFAULT = 'default',
    INSTRUCTOR = 'instructor'
}

export enum InventorySettingV1 {
    BLOCK = 'block',
    FLEX = 'flex',
    NO_DEPENDENCY = 'no_dependency'
}

export interface BookableEventV1 {
    id: string;
    eventId: string;
    accountId: string;
    account: Account;
    bookingId?: string;
    siteId: string;
    duration: number;
    startDate: string;
    endDate: string;
    stopBookable: number;
    attendees: number;
    bookedAttendees: number;
    originalBookedAttendees?: number;
    instructors?: string[];
    createdAt?: string;
    updatedAt?: string;
    info?: string;
    quantity: number;
    cost: number;
    vat: number;
    priceGroup?: string | PriceGroupV1;
}

export interface PriceGroupV1 {
    cost: number;
    en: string;
    sv?: string;
    es?: string;
    da?: string;
    no?: string;
}

export interface EventV1 {
    id: string;
    accountId: string;
    siteId: string;
    artNumber: string;
    ean: string;
    name: LanguageTextTypeV1Alpha1 | string;
    shortName: string;
    type: EventTypeV1;
    createdAt: string;
    updatedAt: string;
    shortDescription?: string;
    longDescription?: string;
    cost: number;
    vat: number;
    sortOrder: number;
    inventorySetting: InventorySettingV1;
    inventory: number;
    productImageUrl: string;
    connectedRentalProducts?: string[];
    bookableEvents?: BookableEventV1[];
    infoText?: string;
    duration: number;
    priceGroup?: {
        [key: string]: PriceGroupV1;
    };
}

export interface PromoCodeV2Alpha2 {
    id: string;
    type: PromoCodeType;
    accountId: string;
    siteId?: string;
    code: string;
    percentDiscount?: number;
    valueDiscount?: number;
    numberOfUsed: number;
    maxNumberUsage?: number;
    maxNumberUsageDay?: number;
    maxProductsPerOrder?: number;
    maxOrderDuration: number;
    maxNumberCustomerUsage?: number;
    minOrderValue?: number;
    maxOrderValue?: number;
    productType: ProductTypes;
    validProducts: [];
    description?: {
        en: string;
        sv: string;
    };
    longDescription?: string;
    personalCode?: boolean;
    validUntil: string;
    validFrom: string;
    created: string;
    updated: string;
    customer?: string;
    active: boolean;
    coupon: string;
    cost: number;
    imageUrl?: string;
    name?: LanguageTextTypeV1Alpha1;
    amount: number;
    useAmount: number;
    validityPeriod: number;
    productVariants: ProductVariants[];
    selectedProductValue?: number;
    uuid?: string;
}

export interface PromoCodeCheckoutLightweightV1Alpha1 {
    id: string;
    productVariantUUID: string;
}

export interface ProductVariants {
    name?: LanguageTextTypeV1Alpha1;
    artNr?: string;
    worth?: string;
    price?: number;
    uuid?: string;
}

export interface SiteConfigurationV1Alpha1 {
    siteId: string;
    maxHours?: number;
    maxDays?: number;
    minHours?: number;
    minDays?: number;
    closedDates?: BlockedDate[];
}

export interface TimePeriod {
    beforeStartTime: number;
    id: string;
    isHighSeason: boolean;
    openEndDate: string;
    openStartDate: string;
    siteId: string;
    openHours: OpenHoursDateV1Alpha1[];
}

export interface OpenHoursDateV1Alpha1 {
    open: string;
    close: string;
    pickupHours: OpenHoursProps[];
    returnHours: OpenHoursProps[];
}

interface BlockedDate {
    date: string | Date;
    pickupHours: OpenHoursProps[];
    returnHours: OpenHoursProps[];
}

export interface OpenHoursProps {
    enabled: boolean;
    time: string;
}

export enum BookingPaymentTypes {
    INVOICE = 0,
    SWISH = 1,
    CARD_PAYMENT = 2,
    MANUAL_INVOICE = 3,
    MANUAL_SWISH = 4,
    MANUAL_CARD_PAYMENT = 5
}

export enum BookingStatus {
    RESERVED,
    OK,
    CANCELED
}

export enum BookingPaymentStatusType {
    UNPAID = 0,
    PAID = 1,
    PENDING = 2,
    PARTIAL_PAID = 3,
    FAILED = 4
}

export const BookingPaymentStatus = {
    0: 'UNPAID',
    1: 'PAID',
    2: 'PENDING',
    3: 'PARTIAL_PAID',
    4: 'FAILED'
};

export enum LockerTypeV1Alpha1 {
    IGLOO_PADLOCK = 'IGLOO_PADLOCK',
    GOKAYA_8E = 'GOKAYA_8E',
    STORAGE_LOCK = 'STORAGE_IGLOO_LOCK',
    MANUAL_LOCKER = 'MANUAL_LOCKER',
    STORAGE_MANUAL_LOCKER = 'STORAGE_MANUAL_LOCKER'
}

export interface ICustomField {
    id: string;
    type: 'TEXT' | 'DROPDOWN' | 'CHECKBOX' | 'RATING' | 'IMAGE_UPLOAD' | 'VIDEO_SHOW';
    MULTISELECT;
    NUMBER;
    name: LanguageTextTypeV1Alpha1;
    isActive: boolean;
    isMandatory: boolean;
    options?: ICustomFieldOption[];
    /** DATA is where the data is stored (answers etc) */
    data?: ICustomFieldData;
    /** KEY is used to identify what part of the system the field is related to. */
    key: string;
    accountId: string;
    order?: number;
}

export interface ICustomFieldOption {
    id: string;
    name: LanguageTextTypeV1Alpha1;
    value: string | LanguageTextTypeV1Alpha1;
}

export interface ICustomFieldData {
    id?: string;
    customFieldId: string;
    customFieldKey: string;
    relatedToId: string;
    value?: string;
}

export interface ICustomFieldComponentModel {
    id: string;
    name: string;
    order: number;
    required: boolean;
    active: boolean;
    customControl: ReactJSXElement | undefined;
}
