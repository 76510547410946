import React, { useEffect, useState } from 'react';
import { FormControl, FormHelperText, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent, Stack, Typography } from '@mui/material';
import { ICustomField } from '../product/interfaces';
import { fetchTranslation, getCurrentLanguageFromLocalStorage } from '../../utils/languageTranslation.util';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface CustomFieldDropdownProps {
    customField: ICustomField;
    onChange: Function;
    control: Control;
    errors: any;
}

export default function CustomFieldDropdown({ customField, onChange, control, errors }: CustomFieldDropdownProps) {
    const [val, setVal] = useState<string>('');
    const { t } = useTranslation();

    useEffect(() => {
        setVal('');
        onChange(customField, '');
    }, []);

    const handleChange = (event: SelectChangeEvent) => {
        setVal(event.target.value);
        onChange(customField, event.target.value);
    };

    return (
        <Stack spacing={1}>
            <Typography variant="body2" sx={{ fontWeight: 600 }}>{customField.isMandatory && <span style={{ color: 'red', marginRight: '4px', fontWeight: 600 }}>*</span>}</Typography>
            <Controller
                name={customField.id}
                control={control}
                defaultValue=''
                rules={{
                    required: customField.isMandatory
                }}
                render={({ field }) => (
                    <FormControl
                        variant="outlined"
                        size="small"
                        error={Boolean(errors[customField.id])}
                        key={customField.id}>
                        <InputLabel id={customField.id}>{fetchTranslation(customField.name)}</InputLabel>
                        <Select
                            labelId={customField.id}
                            id={customField.id}
                            label={customField.name[getCurrentLanguageFromLocalStorage() || 'en'] ?? customField.name['sv']}
                            {...field}
                            error={Boolean(errors[customField.id])}
                        >
                            {customField.options?.map((o) => <MenuItem key={o.id} value={o.value as string}>{fetchTranslation(o.name)}</MenuItem>)}
                        </Select>
                        {errors[customField.id] && (
                            <FormHelperText error>
                                {errors[customField.id]?.message}
                            </FormHelperText>
                        )}
                    </FormControl>
                )}
            />
        </Stack>
    );
}
