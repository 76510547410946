import { Box, Typography } from '@mui/material';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { PinCodeResponseExtended, Product, SiteSectionSettings } from '../../product/interfaces';
import { getCurrentLanguageFromLocalStorage } from '../../../utils/languageTranslation.util';
import { useTranslation } from 'react-i18next';
import { LockerItem } from '../../unlock/interface';
import { ProductPinListContainer } from './ProductPinListContainer';


interface PinListitemProps {
    pinData: PinCodeResponseExtended;
    lockerItems: LockerItem[];
    products: Product[];
    siteSettingsShowPincodes?: boolean;
    showImages?: boolean;
}

export const PinListitem = ({ pinData, products, lockerItems, siteSettingsShowPincodes, showImages }: PinListitemProps) => {

    const getLocker = () => {
        return lockerItems.find((lock) => lock.lockerNumber === pinData?.lockerNumber);
    };

    const getProduct = (lock) => {
        return products.find((prod) => prod.id === lock?.productId);
    };

    const lockerItem = getLocker();
    const productItem = getProduct(lockerItem);
    const { t } = useTranslation();

    const getName = (): string => {
        if (productItem) { return productItem?.name[getCurrentLanguageFromLocalStorage()] || productItem?.name['sv']; }
        if (typeof pinData.productName === 'string') {
            return pinData.productName;
        } else {
            return pinData.productName[getCurrentLanguageFromLocalStorage()] || pinData.productName['sv'];
        }
    };

    const printName = (): string => {
        const item = getName();
        if (item.length < 45) {
            return item;
        } else {
            return item.slice(0, 44) + '...';
        }
    };

    const addSpacesOnPinCodeName = (pinName: string): string => {
        let newName = pinName.replace(/[^\dA-Z]/g, '').replace(/(.{3})/g, '$1 ').trim();
        return newName !== null ? newName : pinName
    }

    return (
        <ProductPinListContainer showImages={showImages}>

            {showImages !== false && (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', pl: '1rem' }}>
                    {productItem?.productImageURL && <img src={productItem.productImageURL} style={{ maxWidth: '45px', maxHeight: '45px' }} />}
                </Box>
            )}
            <Box sx={{ pl: '1rem' }}>
                <Typography className="productHeading" textAlign="left" fontSize={12}>
                    {printName()}
                </Typography>
            </Box>
            <Box>
                {pinData.lockerNumber ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right', flexWrap: 'nowrap', gap: '0.2rem', pr: 3 }}>
                        <Typography fontSize={14}>{t('translation.beforeUnlock.lock')}</Typography>
                        <Typography fontSize={14} variant="subtitle2" className="info">
                            {pinData.lockerNumber.toString()}
                        </Typography>
                    </Box>
                ) : (
                    ''
                )}

                {siteSettingsShowPincodes && pinData.pinCode ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right', flexWrap: 'nowrap', gap: '0.2rem', pr: 3 }}>
                        <Typography fontSize={14}>{t('translation.orderConfirmation.pinCode')}:</Typography>
                        <Typography fontSize={14} variant="subtitle2" className="info">
                            {addSpacesOnPinCodeName(pinData.pinCode)}
                        </Typography>
                        <LockOpenIcon sx={{ fontSize: 14, transform: 'scaleX(-1)', mt: '-2px' }} />
                    </Box>
                ) : (
                    ''
                )}
            </Box>
        </ProductPinListContainer>
    );
};
