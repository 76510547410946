/* eslint-disable react/no-danger */
/* eslint-disable react/destructuring-assignment */
import React, { useCallback, useEffect, useState, useMemo, useContext } from 'react';
import { Dialog, Box, IconButton, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Site } from './interfaces';
import { getCurrentLanguageFromLocalStorage, translate } from '../../utils/languageTranslation.util';
import axiosInstance from '../../utils/axios';
import './TermsAndConditionDialog.scss';
import { BookingFormContext } from '../booking/BookingFormContext';

interface Props {
    showModal: boolean;
    closeModal: () => void;
    siteId: string;
}

const TermsAndConditionDialog = ({ showModal, closeModal, siteId }: Props) => {
    const bookingFormContext = useContext(BookingFormContext);
    const [siteData, setSiteData] = useState<Site | null>(null);

    useEffect(() => {
        setSiteData(bookingFormContext.site);
    }, [bookingFormContext])

    const [contentRef, setContentRef] = useState<any>(null);
    const TermsAndConditionMemo = useMemo(() => {
        let termsText = translate(siteData?.textTOC || '', getCurrentLanguageFromLocalStorage()) ?? '';
        termsText = termsText.replaceAll('<p></p>', '<br>');
        return termsText;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [siteData, getCurrentLanguageFromLocalStorage()]);

    return (
        <div className="TermsAndConditionDialog-wrap">
            <div ref={setContentRef}></div>
            <Dialog
                open={showModal}
                aria-labelledby="form-dialog-title"
                maxWidth="xs"
                container={contentRef}
                sx={{ maxHeight: '80%', top: '10%', zIndex: 2147483647 }}
                onClose={closeModal}>
                <Box sx={{ p: 2 }}>
                    <IconButton
                        aria-label="close"
                        onClick={closeModal}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            zIndex: 9999
                        }}>
                        <CloseIcon />
                    </IconButton>
                    {siteData && <div dangerouslySetInnerHTML={{ __html: TermsAndConditionMemo }} />}
                </Box>
            </Dialog>
        </div>
    );
};

export default TermsAndConditionDialog;
