import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import axios from '../../utils/axios';
import { useTranslation } from 'react-i18next';
import { Box, Button, CircularProgress, Container, Skeleton, Typography } from '@mui/material';
import { FormsResponse } from './interface';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { getCurrentLanguageFromLocalStorage, translate } from '../../utils/languageTranslation.util';
import GoogleReviews from './components/GoogleReviews';
import './ReturnStyles.scss';
import { ICustomField, ICustomFieldComponentModel, ICustomFieldData, LockerStatusEnum, PinCodeResponse, Product, Site } from '../product/interfaces';
import { generateCustomFieldControl, generateCustomFieldValidationSchema } from '../customFields/customFIeldControlFactory';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import axiosInstance from '../../utils/axios';
import { BookingItem, LockerItem } from '../unlock/interface';
import { ProductsList } from '../booking/productDisplayCard/ProductsList';
import { ShowReturnContext } from "./ReturnContext";

interface Props {
    booking: BookingItem;
    siteId: string;
    pinCodesItems: PinCodeResponse[];
    siteInformation: Site | null;
    lockerItems: LockerItem[];
}

export default function ReturnPage({ booking, siteId, pinCodesItems, siteInformation, lockerItems }: Props) {
    const [isSendAnswersLoading, setIsSendAnswersLoading] = useState(false);
    const [isCustomFieldsLoading, setIsCustomFieldsLoading] = useState(false);
    const [isReturned, setIsReturned] = useState(false);
    const [formData, setFormData] = useState<FormsResponse | null>(null);
    const { t } = useTranslation();
    const [useCustomFields, setUseCustomFields] = useState(false);
    const [products, setProducts] = useState<Product[]>([]);
    const { showReturn, setShowReturn } = useContext(ShowReturnContext);
    const [lockerStatus, setLockerStaus] = useState<LockerStatusEnum>(LockerStatusEnum.EARLY)

    const getAllProducts = async (siteId: string) => {
        try {
            const response = await axios.get(`product/v1/allProducts/${siteId}`);
            setProducts(response.data);
        } catch (error) {
            console.error("Error fetching products:", error);
        }
    };

    const getFormData = useCallback(async () => {
        try {
            const response = await axios.get<FormsResponse>(`sites/v1/questionnaire/${siteId}/${booking.id}`);
            if (response.status === 200) {
                const data = response.data;
                setFormData(data);

                if (data.submitted) {
                    setIsReturned(true);
                }
            }
        } catch (error) {
            console.error("Couldn't get form data", error);
        }
    }, [siteId, booking.id]);

    useEffect(() => {
        if (!siteId) return;

        getFormData();

        const fetchCustomFields = async () => {
            setIsCustomFieldsLoading(true);
            if (siteInformation?.returnPageSettings?.customFields?.length) {
                setUseCustomFields(true);
            }
            setIsCustomFieldsLoading(false);
        };

        fetchCustomFields();

        (async () => {
            await getAllProducts(siteId);
        })();

        if (booking.pickUpState === 'Returned' && !isReturned) {
            setIsReturned(true);
        }
    }, [siteId, getFormData, siteInformation?.returnPageSettings?.customFields, booking.pickUpState, isReturned]);

    async function returnOrder() {
        setIsSendAnswersLoading(true);
        try {
            const response = await axios.get(`hardware/locker/return/${booking.id}`);
            if (response.status === 200) {
                setIsReturned(true);
            } else {
                console.error('Something went wrong');
            }
        } catch (error) {
            console.error('Something went wrong');
        } finally {
            setIsSendAnswersLoading(false);
        }
    }

    const formSchema = generateCustomFieldValidationSchema(siteInformation?.returnPageSettings?.customFields, t);

    const {
        handleSubmit,
        control,
        getValues,
        setValue,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(formSchema),
        mode: 'all'
    });

    const handleCustomFieldChange = (field, newValue) => {
        setValue(field.id, newValue);
    };

    const customFields: ICustomFieldComponentModel[] = useMemo(() => {
        if (siteInformation?.returnPageSettings?.customFields) {
            let customFields = siteInformation?.returnPageSettings?.customFields
                .map((field: ICustomField): ICustomFieldComponentModel => {
                    return ({
                        id: field.id,
                        name: field.name[getCurrentLanguageFromLocalStorage() || 'en'] ?? field.name['sv'],
                        order: 999,
                        required: field.isMandatory,
                        active: field.isActive,
                        customControl: generateCustomFieldControl(field, control, handleCustomFieldChange, errors)
                    });
                });

            return customFields;
        }

        return [];
    }, [siteInformation, errors]);

    useEffect(() => {
        if (typeof window.gtag === 'function') {
            window.gtag('event', 'page_view', {
                page_title: 'Return Page'
            });
        }
    }, []);

    const onSaveCustomFieldData = useCallback(async () => {
        setIsSendAnswersLoading(true);

        try {
            let data: ICustomFieldData[] = [];
            siteInformation?.returnPageSettings?.customFields?.forEach((field: ICustomField) => {
                let customFieldData: ICustomFieldData = {
                    customFieldId: field.id,
                    value: getValues(field.id),
                    relatedToId: booking.id,
                    customFieldKey: 'booking_return'
                };
                data.push(customFieldData);
            });

            const response = await axiosInstance.post(`customFields/v1/customfielddata`, data);

            if (response.status >= 200 && response.status < 300) {
                returnOrder();
            }
        } catch (error) {
            console.error('❌ Error saving custom field data:', error);
        } finally {
            setIsSendAnswersLoading(false);
        }
    }, [siteInformation, booking]);


    const goToUnlockPage = () => {
        setShowReturn(false);
    };

    return (
        <ShowReturnContext.Provider value={{ showReturn, setShowReturn }}>
            <Box className="returnPage-box">
                {!isReturned && booking && (
                    <Typography
                        variant="body1"
                        sx={{
                            justifySelf: 'flex-start',
                            textDecoration: "underline",
                            cursor: "pointer",
                            "&:hover": { color: "#768881" },
                            alignSelf: "flex-start",
                            fontSize: '0.875rem',
                            marginLeft: '-8px'
                        }}
                        onClick={goToUnlockPage}
                    >
                        {t('translation.return.back')}
                    </Typography>
                )}
                {siteInformation?.returnPageSettings?.showHeading !== false && (
                    <h2>
                        {siteInformation?.returnPageSettings?.returnHeadings
                            ? siteInformation.returnPageSettings.returnHeadings[getCurrentLanguageFromLocalStorage()]
                            : t('translation.return.confirmReturn')}
                    </h2>
                )}
                {siteInformation && siteInformation?.returnPageSettings?.showProducts !== false &&
                    !isReturned &&
                    <ProductsList
                        pinDataArray={pinCodesItems}
                        products={products}
                        bookingInfoProducts={booking.products}
                        lockerItems={lockerItems}
                        showImages={siteInformation.unlockDisplaySettings?.showImages}
                        siteSettingsShowPincodes={siteInformation?.returnPageSettings?.showPinCodes ? siteInformation.returnPageSettings.showPinCodes : true}
                    />
                }
                <Box
                    className="returnPageCustomFieldsContainer"
                    sx={{ border: useCustomFields ? '1px solid' : 'none', borderColor: isReturned ? 'transparent' : 'primary.main' }}
                >
                    {isCustomFieldsLoading ? (
                        <>
                            <Skeleton variant="text" height={40} />
                            <Skeleton variant="text" height={40} />
                            <Skeleton variant="text" height={40} />
                            <Skeleton variant="text" height={40} />
                        </>
                    ) :
                        isReturned ? (
                            <div style={{ display: 'flex', gap: '8px', alignItems: 'center', justifyContent: 'center' }}>
                                <CheckCircleIcon sx={{ color: '#00C853', fontSize: '40px' }} />
                                <h4>{t('translation.return.thankYouCompleted')}</h4>
                            </div>
                        ) : useCustomFields ? (
                            <form>
                                {customFields.map((field) => field.customControl && (
                                    <Container className="container" key={field.id}>
                                        {field.customControl}
                                    </Container>
                                ))}
                                <Box className='end-booking-btn'>
                                    <Button variant="contained" color="primary" onClick={handleSubmit(onSaveCustomFieldData)} disabled={isSendAnswersLoading}>
                                        {isSendAnswersLoading ? <CircularProgress size="small" color="primary" sx={{ width: '20px' }} /> : t('translation.return.completeButton')}
                                    </Button>
                                </Box>
                            </form>
                        ) : (
                            <Box className='end-booking-btn'>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{ borderRadius: useCustomFields ? '0 0 8px 8px' : '8px !important' }}
                                    onClick={() => {
                                        returnOrder();
                                    }}
                                    disabled={isSendAnswersLoading}
                                >
                                    {isSendAnswersLoading ? <CircularProgress size="small" color="primary" sx={{ width: '20px' }} /> : t('translation.return.completeButton')}
                                </Button>
                            </Box>
                        )}
                </Box>

                <div
                    dangerouslySetInnerHTML={{
                        __html: translate(formData?.returnMessage ?? '', getCurrentLanguageFromLocalStorage())
                    }}
                    style={{ marginBottom: '24px', marginTop: '24px' }}></div>
                {formData?.placeId && <GoogleReviews placeId={formData?.placeId} reviewUrl={formData?.reviewLink} />}
            </Box>
        </ShowReturnContext.Provider>
    );
}
