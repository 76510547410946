import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import SignatureCanvas from 'react-signature-canvas';
import { useTranslation } from 'react-i18next';

interface SignaturePadProps {
    hasError?: boolean;
    label?: string;
    onChange: (signatureData: string) => void;
    className?: string;
}

export interface SignaturePadRef {
    clear: () => void;
    isEmpty: () => boolean;
    getData: () => string;
}

const SignaturePad = forwardRef<SignaturePadRef, SignaturePadProps>(
    ({ hasError = false, label, onChange, className }, ref) => {
        const { t } = useTranslation();
        const sigCanvasRef = useRef<SignatureCanvas>(null);

        // Expose methods to parent component
        useImperativeHandle(ref, () => ({
            clear: () => {
                if (sigCanvasRef.current) {
                    sigCanvasRef.current.clear();
                    onChange('');
                }
            },
            isEmpty: () => {
                return sigCanvasRef.current ? sigCanvasRef.current.isEmpty() : true;
            },
            getData: () => {
                return sigCanvasRef.current ? sigCanvasRef.current.toDataURL() : '';
            }
        }));

        const handleEndDrawing = () => {
            if (sigCanvasRef.current) {
                const isEmpty = sigCanvasRef.current.isEmpty();
                const signatureData = isEmpty ? '' : sigCanvasRef.current.toDataURL();
                onChange(signatureData);
            }
        };

        const clearSignature = () => {
            if (sigCanvasRef.current) {
                sigCanvasRef.current.clear();
                onChange('');
            }
        };

        return (
            <Stack spacing={2} className={className}>
                {label && <Typography variant="body2">{label}</Typography>}
                <Typography variant="body2" sx={{ fontWeight: 600 }}>{t('translation.beforeUnlock.yourSignature')}</Typography>
                <Box
                    sx={{
                        border: `2px dashed ${hasError ? '#d32f2f' : '#ccc'}`,
                        backgroundColor: '#ffffff',
                        position: 'relative',
                        marginTop: '8px !important'
                    }}
                >
                    <SignatureCanvas
                        ref={sigCanvasRef}
                        canvasProps={{
                            height: 150,
                            className: 'signature-canvas',
                            style: { width: '100%' }
                        }}
                        onEnd={handleEndDrawing}
                    />
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={clearSignature}
                        sx={{ minWidth: '80px', borderColor: 'transparent', marginRight: '3px', marginBottom: '3px', position: 'absolute', right: 0, bottom: 0 }}
                    >
                        {t('translation.beforeUnlock.clear')}
                    </Button>

                </Box>
            </Stack>
        );
    }
);

SignaturePad.displayName = 'SignaturePad';

export default SignaturePad;