import React, { useMemo, useRef } from 'react';
import { Checkbox, FormControl, FormControlLabel, FormHelperText, Stack, Typography, Button, Box, Paper } from '@mui/material';
import { ICustomField } from '../product/interfaces';
import { fetchTranslation, getCurrentLanguageFromLocalStorage, LanguageTextTypeV1Alpha1 } from '../../utils/languageTranslation.util';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import SignaturePad, { SignaturePadRef } from '../elements/SignaturePad';
import './css/customFieldVideoShow.scss';

interface CustomFieldVideoShowProps {
  customField: ICustomField;
  onChange: Function;
  errors: Record<string, any>;
  control: Control<any>;
}

export default function CustomFieldVideoShow({ customField, onChange, errors, control }: CustomFieldVideoShowProps) {
  const { t } = useTranslation();
  const signaturePadRef = useRef<SignaturePadRef>(null);

  // Extract video URL and options with proper typing
  const videoConfig = useMemo(() => {
    if (!customField?.options) return { embedHtml: '', autoplay: false };

    const embedUrlOption = customField.options.find(o => o.id === 'embedUrl');
    const autoplayOption = customField.options.find(o => o.id === 'autoplay');
    const autoplay = autoplayOption?.value ?? false;

    const videoUrl = embedUrlOption ? fetchTranslation(embedUrlOption.value as LanguageTextTypeV1Alpha1) : '';

    // Extract YouTube ID and create embed URL with proper parameters
    const youtubeRegex = /(?:https:\/\/)?(?:http:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?(.+)/;
    const match = videoUrl.match(youtubeRegex);
    const youtubeId = match ? match[1] : '';

    const autoplayParams = autoplay ? '?&autoplay=1&mute=1' : '';
    const embedHtml = youtubeId ?
      `<iframe width="100%" style="aspect-ratio: 16 / 9;" src="https://www.youtube.com/embed/${youtubeId}${autoplayParams}" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>`
      : '';

    return { embedHtml, autoplay };
  }, [customField?.options]);

  // Get signature text
  const signatureText = useMemo(() => {
    if (!customField?.options) return '';
    const option = customField.options.find(o => o.id === 'signatureText');
    return option ? fetchTranslation(option.value as LanguageTextTypeV1Alpha1) : '';
  }, [customField?.options]);

  return (
    <Controller
      name={customField.id}
      control={control}
      defaultValue=""
      rules={{
        required: customField.isMandatory
      }}
      render={({ field }) => (
        <FormControl
          error={Boolean(errors[customField.id])}
          fullWidth
          component="fieldset"
          variant="standard"
          sx={{ mb: 2 }}
        >
          <Typography
            variant="subtitle1"
            gutterBottom
            fontWeight={500}
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            {customField.isMandatory && <span style={{ color: 'red', marginRight: '4px', fontWeight: 600 }}>*</span>}
            {fetchTranslation(customField.name)}
          </Typography>

          {videoConfig.embedHtml && (
            <div
              className="video-container"
              dangerouslySetInnerHTML={{ __html: videoConfig.embedHtml }}
              style={{ marginBottom: '8px' }}
            />
          )}

          {signatureText && (
            <SignaturePad
              ref={signaturePadRef}
              hasError={Boolean(errors[customField.id])}
              label={signatureText}
              onChange={(signatureData) => {
                field.onChange(signatureData);
                onChange(customField, signatureData);
              }}
            />
          )}

          {!signatureText && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(field.value)}
                  onChange={(e) => {
                    field.onChange(e.target.checked);
                    onChange(customField, e.target.checked);
                  }}
                />
              }
              label={t('translation.beforeUnlock.watchedVideoConfirm')}
            />
          )}

          {errors[customField.id] && (
            <FormHelperText error>
              {errors[customField.id].message ||
                `${fetchTranslation(customField.name)} ${t('translation.cartStep.genericIsRequired')}`}
            </FormHelperText>
          )}
        </ FormControl>
      )}
    />
  );
}
