import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import BookingFrame from './components/booking/BookingFrame';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Theme from './theme';
import BookingButton from './components/booking/BookingButton';
import IframeWrapper from './components/booking/IframeWrapper';
import { initializeI18n } from './i18n';
import { LicenseInfo } from '@mui/x-date-pickers-pro';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY ?? '');

const classElements: any = document.querySelectorAll('#gokaya-checkout, #rentalbuddy-checkout');
var forceOpen = /[?&]showConfirm=true/.test(window.location.search) || /[?&]unlockOrder=/.test(window.location.search);
const searchParams = new URLSearchParams(window.location.search);

const urlSiteId = searchParams.get('siteId') ?? '';
var lockerId = searchParams.get('lockerId');

// enum with force show either none, event or product
export enum ForceShowEnum {
    None = 'none',
    Event = 'event',
    Product = 'product'
}

if (classElements && classElements.length > 0) {
    for (var i = 0; i < classElements.length; i++) {
        if (classElements[i] && !classElements[i].hasAttribute('data-app-initialized')) {
            const siteId = classElements[i].dataset.site;
            const buttonText = classElements[i].dataset.buttontext;
            const showType = classElements[i].dataset.showtype;
            const forceOpenWithUrl = siteId === urlSiteId && forceOpen;
            const showLockerId = urlSiteId === '' ? (lockerId as string) : siteId === urlSiteId ? (lockerId as string) : '';

            if (i === 0 && document.getElementById('gokaya-iframe') === null) {
                ReactDOM.render(<IframeWrapper />, document.body.appendChild(document.createElement('DIV')));
            }

            if (siteId) {
                const bookingType = classElements[i].dataset.bookingtype;
                let forceShowType = ForceShowEnum.None;

                switch (showType) {
                    case 'event':
                        forceShowType = ForceShowEnum.Event;
                        break;
                    case 'product':
                        forceShowType = ForceShowEnum.Product;
                        break;
                }
                ReactDOM.render(
                    <React.StrictMode>
                        <ThemeProvider theme={Theme}>
                            {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                            <CssBaseline />
                            <BookingButton
                                siteId={siteId}
                                forceOpen={urlSiteId === '' ? forceOpen : forceOpenWithUrl}
                                bookingType={bookingType}
                                lockerId={showLockerId}
                                forceShowType={forceShowType}
                                buttonText={buttonText}
                            />
                        </ThemeProvider>
                    </React.StrictMode>,
                    classElements[i]
                );

                classElements[i].setAttribute('data-app-initialized', 'true');
                if (urlSiteId === '' || forceOpenWithUrl) {
                    forceOpen = false;
                }
                if (showLockerId) {
                    lockerId = '';
                }
            }
        }
    }
} else if (forceOpen) {
    if (document.getElementById('gokaya-iframe') === null) {
        ReactDOM.render(<IframeWrapper />, document.body.appendChild(document.createElement('DIV')));
    }
    ReactDOM.render(
        <React.StrictMode>
            <ThemeProvider theme={Theme}>
                {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                <CssBaseline />
                <BookingButton
                    siteId={''}
                    forceOpen={forceOpen}
                    bookingType={''}
                    lockerId={lockerId as string}
                    forceShowType={ForceShowEnum.None}
                    hideBookButton={true}
                />
            </ThemeProvider>
        </React.StrictMode>,
        document.body.appendChild(document.createElement('DIV'))
    );
}

const classIframeElements: any = document.querySelectorAll('#gokaya-iframe-content');
if (classIframeElements && classIframeElements.length > 0) {
    for (var y = 0; y < classIframeElements.length; y++) {
        if (classIframeElements[y]) {
            initializeI18n();
            ReactDOM.render(
                <React.StrictMode>
                    <ThemeProvider theme={Theme}>
                        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                        <CssBaseline />
                        <BookingFrame />
                    </ThemeProvider>
                </React.StrictMode>,
                classIframeElements[y]
            );

            // Only force-open one window in case there are multiple buttons
            forceOpen = false;
        }
    }
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
