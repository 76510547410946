import React, { useMemo, useRef, useState } from 'react';
import { Box, Button, Checkbox, Dialog, FormControl, FormControlLabel, FormHelperText, IconButton, Stack, Typography } from '@mui/material';
import { ICustomField } from '../product/interfaces';
import { fetchTranslation, getCurrentLanguageFromLocalStorage, LanguageTextTypeV1Alpha1 } from '../../utils/languageTranslation.util';
import { Control, Controller } from 'react-hook-form';
import SignaturePad, { SignaturePadRef } from '../elements/SignaturePad';
import './css/customFieldTextInfo.scss';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';

interface CustomFieldTextInfoProps {
  customField: ICustomField;
  onChange: Function;
  errors: Record<string, any>;
  control: Control<any>;
}

export default function CustomFieldTextInfo({ customField, onChange, errors, control }: CustomFieldTextInfoProps) {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const signaturePadRef = useRef<SignaturePadRef>(null);

  // Get signature text
  const signatureText = useMemo(() => {
    if (!customField?.options) return '';
    const option = customField.options.find(o => o.id === 'signatureText');
    return option ? fetchTranslation(option.value as LanguageTextTypeV1Alpha1) : '';
  }, [customField?.options]);

  const useSignature = customField?.options?.find(o => o.id === 'confirmationMethod')?.value === 'SIGNATURE';
  const isMandatory = customField?.isMandatory;

  const textInfo = fetchTranslation(customField.options?.find(o => o.id === 'textInfo')?.value as LanguageTextTypeV1Alpha1);
  const textInPopup = fetchTranslation(customField.options?.find(o => o.id === 'textInPopup')?.value as LanguageTextTypeV1Alpha1);

  return (
    <Box>
      <Controller
        name={customField.id}
        control={control}
        defaultValue=""
        rules={{
          required: customField.isMandatory,
          validate: value => {
            // Custom validation for signature or checkbox
            if (customField.isMandatory && !value) {
              return `${fetchTranslation(customField.name)} is required`;
            }
            return true;
          }
        }}
        render={({ field }) => (
          <FormControl
            fullWidth
            error={Boolean(errors[customField.id])}
            sx={{ mb: 2 }}
          >

            <Typography
              className="text-info">
              {customField.isMandatory && <span style={{ color: 'red', marginRight: '4px', fontWeight: 600 }}>*</span>}
              <span dangerouslySetInnerHTML={{ __html: textInfo }} />
            </Typography>

            <Typography variant="subtitle1" gutterBottom className="linkText" onClick={() => setShowModal(true)}>
              {fetchTranslation(customField.options?.find(o => o.id === 'linkText')?.value as LanguageTextTypeV1Alpha1)}
            </Typography>

            {/* Use the new SignaturePad component */}
            {(isMandatory && useSignature && signatureText) && (
              <SignaturePad
                ref={signaturePadRef}
                hasError={Boolean(errors[customField.id])}
                label={signatureText}
                onChange={(signatureData) => {
                  field.onChange(signatureData);
                  onChange(customField, signatureData);
                }}
              />
            )}

            {/* Confirmation checkbox (if no signature) */}
            {isMandatory && !useSignature && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={Boolean(field.value)}
                    onChange={(e) => {
                      field.onChange(e.target.checked);
                      onChange(customField, e.target.checked);
                    }}
                  />
                }
                label={signatureText}
              />
            )}

            {errors[customField.id] && (
              <FormHelperText error>
                {errors[customField.id].message || `${fetchTranslation(customField.name)} is required`}
              </FormHelperText>
            )}
          </FormControl>
        )}
      />

      <Dialog
        open={showModal}
        onClose={() => { }}
        maxWidth="xs"
        sx={{ maxHeight: '80%', top: '10%', zIndex: 2147483647 }}
      >
        <Box sx={{ p: 2, minWidth: '350px' }}>
          <IconButton
            aria-label="close"
            onClick={() => setShowModal(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              zIndex: 9999
            }}>
            <CloseIcon />
          </IconButton>

          <div
            className="dialog__text-info"
            dangerouslySetInnerHTML={{ __html: textInPopup }}
            style={{ marginBottom: '1rem' }}
          />
        </Box>
      </Dialog>
    </Box>
  );
}
