import React, { ReactNode } from "react";
import { Box } from "@mui/material";

interface ProductPinListContainerProps {
    showImages?: boolean
    children?: ReactNode;
}

export const ProductPinListContainer = ({ showImages, children }: ProductPinListContainerProps) => {

    const setGrid = () => {
        return showImages !== false ? '16.67% 41.67% 41.67%' : '53.33% 41.67%';
    };
    return <Box
        sx={{
            width: '100%',
            border: '1px solid #bbb',
            mb: 2,
            height: '60px',
            alignItems: 'center',
            mt: 0,
            borderRadius: '100px',
            ml: 0,
            display: 'grid',
            gridTemplateColumns: setGrid()
        }}>{children}</Box>
}