import { Box, Skeleton } from '@mui/material';
import SkeletonConfirmProduct from './SkeletonConfirmProduct';

export default function SkeletonConfirm() {
    return (
        <Box sx={{ p: 2, position: 'relative' }}>
            <Skeleton width={'100%'} height={40} />
            <Skeleton width={'100%'} height={40} />
            <Skeleton width={'100%'} height={40} />
            <Skeleton width={'100%'} height={40} />
            <Skeleton width={'100%'} height={40} sx={{ marginBottom: '16px' }} />
            <SkeletonConfirmProduct />
            <SkeletonConfirmProduct />
            <SkeletonConfirmProduct />
            <Skeleton width={'100%'} height={40} />
            <Skeleton width={'100%'} height={40} />
            <Skeleton variant="rectangular" width={'100%'} height={300} sx={{ marginTop: '8px' }} />
        </Box>
    );
}
