import './BeforeOpenLock.scss';
import { Box, Button, Container, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { ICustomField, ICustomFieldComponentModel, ICustomFieldData, PinCodeResponse, Product, ProductTypes, SiteBeforeBookingSettingsV1 } from '../product/interfaces';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import CustomFieldCheckbox from '../customFields/customFieldCheckbox';
import CustomFieldDropdown from '../customFields/customFieldDropdown';
import CustomFieldText from '../customFields/customFieldText';
import { useCallback, useMemo } from 'react';
import { getCurrentLanguageFromLocalStorage } from '../../utils/languageTranslation.util';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import CustomFieldRating from '../customFields/customFieldRating';
import CustomFieldImageUpload from '../customFields/customFieldUploadImage';
import axiosInstance from '../../utils/axios';
import { BookingItem, LockerItem } from '../unlock/interface';
import { ImageWithSkeleton, skeletonShapeEnum } from '../elements/ImageWithSkeleton';
import ErrorBoundary from '../elements/ErrorBoundary';
import { generateCustomFieldControl, generateCustomFieldValidationSchema } from '../customFields/customFIeldControlFactory';
import { ProductsList } from '../booking/productDisplayCard/ProductsList';

interface Props {
    setRequireBeforeFlow: (value: boolean) => void;
    beforeBookingSettings: SiteBeforeBookingSettingsV1;
    booking: BookingItem;
    lockerItems: LockerItem[];
    products: Product[];
    pinCodesItems: PinCodeResponse[];
    showProductImages: boolean | undefined;
}

const BeforeOpenLockPage: React.FC<Props> = ({ setRequireBeforeFlow, beforeBookingSettings, booking, lockerItems, products, pinCodesItems, showProductImages }) => {
    const { t } = useTranslation();

    const formSchema = generateCustomFieldValidationSchema(beforeBookingSettings?.customFields, t);
    console.log(formSchema);

    const onSaveCustomFieldData = useCallback(async () => {
        let data: ICustomFieldData[] = [];
        beforeBookingSettings.customFields?.forEach((field: ICustomField) => {
            let customFieldData: ICustomFieldData = {
                customFieldId: field.id,
                value: getValues(field.id),
                relatedToId: booking.id,
                customFieldKey: 'booking_before'
            }
            data.push(customFieldData);
        });

        const response = await axiosInstance.post(`customFields/v1/customfielddata`, data);

        if (response.status >= 200 && response.status < 300) {
            setRequireBeforeFlow(false);
        }
        else {

        }
    }, [beforeBookingSettings.customFields, booking]);

    const {
        handleSubmit,
        control,
        register,
        getValues,
        watch,
        setValue,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(formSchema),
        mode: 'all'
    });

    const handleCustomFieldChange = (field, newValue) => {
        setValue(field.id, newValue);
    };

    const customFields: ICustomFieldComponentModel[] = useMemo(() => {
        if (beforeBookingSettings.customFields) {
            let customFields = beforeBookingSettings.customFields
                .map((field: ICustomField): ICustomFieldComponentModel => {
                    return ({
                        id: field.id,
                        name: field.name[getCurrentLanguageFromLocalStorage() || 'en'] ?? field.name['sv'],
                        order: field.order ?? -1,
                        required: field.isMandatory,
                        active: field.isActive,
                        customControl: generateCustomFieldControl(field, control, handleCustomFieldChange, errors)
                    });
                });

            return customFields;
        }

        return [];
    }, [beforeBookingSettings, errors]);

    return (
        <form>
            <div className="beforeOpenLockPage">
                <h2>{beforeBookingSettings?.header[getCurrentLanguageFromLocalStorage() || 'en']}</h2>
                {beforeBookingSettings.showProducts !== false &&
                    <ProductsList
                        pinDataArray={pinCodesItems}
                        sectionData={beforeBookingSettings}
                        products={products}
                        bookingInfoProducts={booking.products}
                        lockerItems={lockerItems}
                        showImages={showProductImages}
                        showPinCodes={beforeBookingSettings.showPinCodes} />
                }

                {customFields
                    .sort((a, b) => a.order - b.order)
                    .map((field) => {
                        if (field.customControl) {
                            return <Container className="container" key={field.id}>{field.customControl}</Container>;
                        }
                    })}
                <Box className="goToOpenLockPage-btn">
                    <Button
                        onClick={handleSubmit(onSaveCustomFieldData)}
                        disabled={Object.keys(errors).length > 0}>
                        {t('translation.beforeUnlock.goToOpenLockPage')}
                    </Button>
                </Box>
            </div>
        </form>
    );
};

export default BeforeOpenLockPage;
