import { Box, Skeleton } from '@mui/material';
import React from 'react';

export default function SkeletonConfirmProduct() {
    return (
        <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: '8px' }}>
            <Skeleton variant="rectangular" width={60} height={60} />
            <Skeleton height={40} style={{ marginLeft: '16px', flex: 1 }} />
        </Box>
    );
}
