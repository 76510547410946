import { Box } from '@mui/material';
import { PinCodeResponse, PinCodeResponseExtended, Product, SiteSectionSettings } from '../../product/interfaces';
import { LockerItem, ProductItem } from '../../unlock/interface';
import { PinListitem } from './PinListItem';
import { ProductListitem } from './ProductListItem';

interface ProductsListProps {
    pinDataArray?: PinCodeResponse[];
    lockerItems?: LockerItem[];
    products: Product[];
    bookingInfoProducts: ProductItem[];
    siteSettingsShowPincodes: boolean;
    showImages?: boolean;
}

export const ProductsList = ({
    pinDataArray,
    lockerItems,
    products,
    bookingInfoProducts,
    showImages,
    siteSettingsShowPincodes,
}: ProductsListProps) => {
    const sortBookedProducts = (bookedProducts: ProductItem[]): ProductItem[] => {
        let sortedProducts: ProductItem[] = [];
        bookedProducts.map((prod) => {
            const foundProduct = products.find((pro) => pro.id === prod.productId);
            if (foundProduct!.accessoryPriceTable) {
                sortedProducts.push(prod);
            } else {
                sortedProducts.unshift(prod);
            }
        });
        return sortedProducts;
    };

    const filterBookedProducts = () => {
        const bookingProdsNoPin = bookingInfoProducts.filter((prod) => !prod.lockerId);
        const prodsNoInsurance = products.filter((prod) => !prod.insurancePriceTable);
        let returnedProds: ProductItem[] = [];
        bookingProdsNoPin.map((prod) => {
            if (prodsNoInsurance.find((pro) => pro.id === prod.productId)) returnedProds.push(prod);
        });
        if (returnedProds.length > 1) {
            returnedProds = sortBookedProducts(returnedProds);
        }
        return returnedProds;
    };

    const sortPinCodes = (pinDataArray: PinCodeResponse[]): PinCodeResponseExtended[] => {
        let sortedPinCodes: PinCodeResponseExtended[] = [];
        // This is to make sure we don't just use the same locker when multiple pin and lockers have the same number
        let lockerIdArray: string[] = []
        pinDataArray.map((pin) => {
            const extendedPin: PinCodeResponseExtended = { ...pin, lockerId: '' }
            const isOnLockerItems = lockerItems?.find((lock) => lock.lockerNumber === pin.lockerNumber && lock.id !== lockerIdArray.find((arr) => arr === lock.id));
            if (isOnLockerItems) {
                if (isOnLockerItems.id) {
                    extendedPin.lockerId = isOnLockerItems.id
                    lockerIdArray.push(isOnLockerItems.id)
                }
                sortedPinCodes.unshift(extendedPin);
            } else {
                sortedPinCodes.push(extendedPin);
            }
        });
        return sortedPinCodes;
    };

    const filteredbookingInfoProducts = filterBookedProducts();

    return (
        <Box sx={{ width: '100%' }}>
            {pinDataArray &&
                lockerItems &&
                pinDataArray.length > 0 &&
                sortPinCodes(pinDataArray).map((pin, i) => (
                    <PinListitem
                        pinData={siteSettingsShowPincodes ? pin : { ...pin, pinCode: '' }}
                        lockerItems={lockerItems}
                        products={products}
                        siteSettingsShowPincodes={siteSettingsShowPincodes}
                        showImages={showImages}
                        key={pin.pinCode + i}
                    />

                ))}
            {filteredbookingInfoProducts.length > 0 &&
                filteredbookingInfoProducts.map((prod) => (
                    <ProductListitem products={products} bookingInfoProduct={prod} showImages={showImages} key={prod.id} />
                ))}
        </Box>
    );
};
