import { Box, Typography } from '@mui/material';
import { Product } from '../../product/interfaces';
import { getCurrentLanguageFromLocalStorage } from '../../../utils/languageTranslation.util';

import { ProductItem } from '../../unlock/interface';
import { ProductPinListContainer } from './ProductPinListContainer';


interface ProductListitemProps {
    products: Product[];
    bookingInfoProduct: ProductItem;
    showImages?: boolean;
}

export const ProductListitem = ({ products, showImages, bookingInfoProduct }: ProductListitemProps) => {
    const getProduct = () => {
        return products.find((prod) => prod.id === bookingInfoProduct.productId);
    };

    const productItem = getProduct();

    const getName = (): string => {
        if (productItem) {
            return productItem?.name[getCurrentLanguageFromLocalStorage()] || productItem?.name['sv'];
        } else {
            return '';
        }
    };

    const printName = (): string => {
        const item = getName();
        if (item.length < 45) {
            return item;
        } else {
            return item.slice(0, 44) + '...';
        }
    };

    return (
        <ProductPinListContainer showImages={showImages}>
            {showImages !== false && (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', pl: '1rem' }}>
                    {productItem?.productImageURL && <img src={productItem.productImageURL} width="90%" />}
                </Box>
            )}
            <Box sx={{ pl: '1rem' }}>
                <Typography className="productHeading" textAlign="left" fontSize={12}>
                    {printName()}
                </Typography>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right', flexWrap: 'nowrap', gap: '0.2rem', pr: 3 }}>
                <Typography fontSize={14} variant="subtitle2" className="info">
                    {bookingInfoProduct.quantity} st
                </Typography>
            </Box>
        </ProductPinListContainer>
    );
};
