import { CSSProperties, useEffect, useMemo, useState } from 'react';
import { ForceShowEnum } from '../..';
import { removeURLParameter } from '../../utils/UrlHelper';

interface Props {
    siteId: string;
    forceOpen: boolean;
    bookingType?: string;
    lockerId?: string;
    forceShowType: ForceShowEnum;
    hideBookButton?: boolean;
    buttonText?: string;
}

export const buttonStyle: CSSProperties = {
    backgroundColor: '#004E31',
    fontSize: '16px',
    lineHeight: '24.5px',
    color: 'white',
    border: 'none',
    textAlign: 'center',
    margin: '4px 2px',
    padding: '8px 16px',
    fontFamily: 'Outfit, Roboto, Helvetica, Arial, sans-serif',
    borderRadius: '4px',
    fontWeight: 'bold',
    cursor: 'pointer'
};

const buttonStyleHover: CSSProperties = {
    backgroundColor: '#003622'
};

export default function BookingButton({
    siteId,
    forceOpen,
    bookingType,
    lockerId,
    forceShowType,
    hideBookButton = false,
    buttonText = 'BOOK'
}: Props) {
    const [hover, setHover] = useState(false);
    const searchParams = new URLSearchParams(window.location.search);
    const unlockOrderParam = searchParams.get('unlockOrder');

    const bookingFrameInitialized = (event) => {
        if (event.data.command === 'gokaya-BookingFrameInitialized') {
            openBookingWindow();
        }
    };

    useEffect(() => {
        if (forceOpen) {
            window.addEventListener('message', bookingFrameInitialized);

            if (!unlockOrderParam) {
                //remove params to not block later params
                let newUrl = removeURLParameter(window.location.toString(), 'siteId');
                newUrl = removeURLParameter(newUrl, 'showConfirm');
                window.history.pushState({}, document.title, newUrl);
            }
        }

        return () => {
            window.removeEventListener('message', bookingFrameInitialized);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forceOpen]);

    useEffect(() => {
        if (lockerId) {
            window.addEventListener('message', bookingFrameInitialized);
        }

        return () => {
            window.removeEventListener('message', bookingFrameInitialized);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lockerId]);

    const openBookingWindow = () => {
        const iframeWindow = document.getElementById('gokaya-iframe') as any;
        if (iframeWindow) {
            iframeWindow.style.display = 'block';
            document.body.style.overflow = 'hidden';

            const searchParams = new URLSearchParams(window.location.search);
            var unlockOrderId = searchParams.get('unlockOrder');
            var orderId = searchParams.get('orderId');
            var statusId = searchParams.get('status');
            var returnBookingBoolean = searchParams.get('returnBooking');

            // Remove unnecessary query-parameters
            let newUrl = removeURLParameter(window.location.toString(), 'status');
            newUrl = removeURLParameter(newUrl, 'orderId');
            window.history.pushState({}, document.title, newUrl);

            iframeWindow.contentWindow.postMessage(
                {
                    command: 'gokaya-OpenBookingWindow',
                    detail: {
                        siteId: siteId,
                        bookingType: bookingType,
                        siteUrl: window.document.location.toString(),
                        lockerId: lockerId,
                        forceShowType: forceShowType,
                        unlockOrderId: unlockOrderId ?? '',
                        orderId: orderId ?? '',
                        statusId: statusId ?? '',
                        returnBookingBoolean: (returnBookingBoolean !== null && returnBookingBoolean !== '') ?? false
                    }
                },
                '*'
            );
        }
    };

    const isHoverStyle = useMemo(() => (hover ? buttonStyleHover : {}), [hover]);

    return (
        <>
            {!hideBookButton && (
                <button
                    onClick={() => {
                        openBookingWindow();
                    }}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                    style={{ ...buttonStyle, ...isHoverStyle }}>
                    {/*t('translation.bookingButton')*/}
                    {buttonText}
                </button>
            )}
        </>
    );
}
