import React, { useEffect, useMemo, useState } from 'react';
import BookingsForm from './BookingForm';
import { BookingPaymentProviders } from '../product/interfaces';
import { ForceShowEnum } from '../..';
import UnlockFrame from '../unlock/UnlockFrame';
import ShowConfirmFrame from './ShowConfirmFrame';
import ClosePopup from '../elements/ClosePopup';
import i18n from '../../i18n/index';
import { I18nextProvider } from 'react-i18next';
import ShowStatusLoadingFrame, { InitialCartData } from './ShowStatusLoadingFrame';
import { ThemeColorContext } from '../../theme/themeColorContext';
import { createTheme, ThemeProvider } from '@mui/material';
import theme from '../../theme';

export default function BookingFrame() {
    const [showModal, setShowModal] = useState(true);
    const [initialSite, setInitialSite] = useState('');
    const [lockerId, setLockerId] = useState(0);
    const [QR, setQR] = useState(false);
    const [forceShowType, setForceShowType] = useState(ForceShowEnum.None);
    const [siteId, setSiteId] = useState('');
    const [unlockOrderId, setUnlockOrderId] = useState('');
    const [orderId, setOrderId] = useState('');
    const [statusId, setStatusId] = useState('');
    const [returnBookingBoolean, setReturnBookingBoolean] = useState(false);
    const [bookingType, setBookingType] = useState<BookingPaymentProviders.BILLMATE | BookingPaymentProviders.MANUAL>(
        BookingPaymentProviders.BILLMATE
    );

    const [themeColor, setThemeColor] = useState('#004E31');

    const [initialCartData, setInitialCartData] = useState<InitialCartData | null>(null);

    const [onConfirmFunction, setOnConfirmFunction] = useState<null | (() => void)>(null);

    //closing popup
    const [showClosePopup, setShowClosePopup] = useState(false);
    const handleCloseModal = (confirmFunction?: () => void) => {
        if (confirmFunction) {
            setOnConfirmFunction(() => confirmFunction);
        } else {
            setOnConfirmFunction(null);
        }

        setShowClosePopup(true);
    };

    const handleConfirmClose = () => {
        setShowModal(false);
        setShowClosePopup(false);
    };

    useEffect(() => {
        document.body.style.background = 'none';

        window.addEventListener('message', (event) => {
            if (event.data.command === 'gokaya-OpenBookingWindow') {
                if (event.data.detail.siteId || event.data.detail.unlockOrderId || event.data.detail.orderId) {
                    setReturnBookingBoolean(event.data.detail.returnBookingBoolean ?? false);
                    setUnlockOrderId(event.data.detail.unlockOrderId);
                    setOrderId(event.data.detail.orderId);
                    setStatusId(event.data.detail.statusId);
                    if (event.data.detail.lockerId) {
                        setQR(true);
                    }
                    if (event.data.detail.forceShowType) {
                        setForceShowType(event.data.detail.forceShowType);
                    }
                    setBookingType(event.data.detail.bookingType === 'MANUAL' ? BookingPaymentProviders.MANUAL : BookingPaymentProviders.BILLMATE);
                    setSiteId(event.data.detail.siteId);
                    setShowModal(true);
                    setInitialSite(event.data.detail.siteUrl);
                    setLockerId(event.data.detail.lockerId);
                }
            }
        });
        return () => {
            window.removeEventListener('message', () => {});
        };
    }, []);

    useEffect(() => {
        if (window.parent) {
            window.parent.postMessage({ command: 'gokaya-BookingFrameInitialized', detail: { siteId: siteId } }, '*');
        } else {
            window.postMessage({ command: 'gokaya-BookingFrameInitialized', detail: { siteId: siteId } }, '*');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!showModal) {
            window.postMessage({ command: 'gokaya-HideBookingWindow', detail: { siteId: siteId } }, '*');

            if (window.parent) {
                window.parent.postMessage({ command: 'gokaya-HideBookingWindow', detail: { siteId: siteId } }, '*');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showModal]);

    const themeColorContextValues = useMemo(
        () => ({
            themeColor,
            setThemeColor
        }),
        [themeColor, setThemeColor]
    );

    const siteTheme = createTheme({
        ...theme,
        palette: {
            ...theme.palette,
            primary: {
                main: themeColor
            }
        }
    });

    return (
        <>
            <I18nextProvider i18n={i18n}>
                {showModal && siteId && unlockOrderId === '' && orderId === '' && statusId === '' && (
                    <ThemeColorContext.Provider value={themeColorContextValues}>
                        <ThemeProvider theme={siteTheme}>
                            <BookingsForm
                                showModal={showModal}
                                siteId={siteId}
                                closeModal={handleCloseModal}
                                bookingType={bookingType}
                                initialSite={initialSite}
                                lockerId={lockerId}
                                QR={QR}
                                accountId={''}
                                currency={''}
                                forceShowType={forceShowType}
                                initialCartData={initialCartData}
                                setInitialCartData={setInitialCartData}
                                setUnlockOrderId={setUnlockOrderId}
                            />
                        </ThemeProvider>
                    </ThemeColorContext.Provider>
                )}
                {showModal && orderId && <ShowConfirmFrame showModal={showModal} orderId={orderId} closeModal={handleCloseModal} />}
                {showModal && statusId && orderId === '' && unlockOrderId === '' && (
                    <ThemeColorContext.Provider value={themeColorContextValues}>
                        <ThemeProvider theme={siteTheme}>
                            <ShowStatusLoadingFrame
                                showModal={showModal}
                                statusId={statusId}
                                setStatusId={setStatusId}
                                closeModal={handleCloseModal}
                                setOrderId={setOrderId}
                                setInitialCartData={setInitialCartData}
                                setSiteId={setSiteId}
                                setUnlockOrderId={setUnlockOrderId}
                            />
                        </ThemeProvider>
                    </ThemeColorContext.Provider>
                )}
                {showModal && unlockOrderId && (
                    <ThemeColorContext.Provider value={themeColorContextValues}>
                        <ThemeProvider theme={siteTheme}>
                            <UnlockFrame
                                showModal={showModal}
                                bookingId={unlockOrderId}
                                returnBookingBoolean={returnBookingBoolean}
                                closeModal={handleCloseModal}
                            />
                        </ThemeProvider>
                    </ThemeColorContext.Provider>
                )}
                {showModal && (
                    <ThemeColorContext.Provider value={themeColorContextValues}>
                        <ThemeProvider theme={siteTheme}>
                            <ClosePopup
                                open={showClosePopup}
                                onCancel={() => setShowClosePopup(false)}
                                onConfirm={handleConfirmClose}
                                onConfirmFunction={onConfirmFunction}
                            />
                        </ThemeProvider>
                    </ThemeColorContext.Provider>
                )}
            </I18nextProvider>
        </>
    );
}
