import React from 'react';
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Stack,
    Typography
} from '@mui/material';
import { ICustomField } from '../product/interfaces';
import { fetchTranslation } from '../../utils/languageTranslation.util';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface CustomFieldCheckboxProps {
    customField: ICustomField;
    onChange: Function;
    control: Control<any>;
    errors: Record<string, any>;
}

export default function CustomFieldCheckbox({
    customField,
    onChange,
    control,
    errors
}: CustomFieldCheckboxProps) {
    const { t } = useTranslation();
    const fieldId = `checkbox-${customField.id}`;
    const hasError = Boolean(errors[customField.id]);
    const errorMessage = errors[customField.id]?.message ||
        `${fetchTranslation(customField.name)} ${t('translation.cartStep.genericIsRequired')}`;

    return (
        <Controller
            name={customField.id}
            control={control}
            defaultValue={false}
            rules={{
                validate: value => {
                    if (customField.isMandatory && !value) {
                        return errorMessage;
                    }
                    return true;
                }
            }}
            render={({ field }) => (
                <FormControl
                    error={hasError}
                    fullWidth
                    margin="normal"
                    sx={{ my: 1 }}
                >
                    <FormControlLabel
                        id={fieldId}
                        label={
                            <Typography
                                component="span"
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    ...(hasError && { color: 'error.main' })
                                }}
                            >
                                {fetchTranslation(customField.name)}
                                {customField.isMandatory &&
                                    <Typography
                                        component="span"
                                        color="error"
                                        sx={{ ml: 0.5 }}
                                    >
                                        *
                                    </Typography>
                                }
                            </Typography>
                        }
                        control={
                            <Checkbox
                                checked={field.value}
                                onChange={(e) => {
                                    // Update both the form state and parent component
                                    field.onChange(e.target.checked);
                                    onChange(customField, e.target.checked);
                                }}
                                onBlur={field.onBlur}
                                inputRef={field.ref}
                                sx={{
                                    color: hasError ? 'error.main' : 'primary.main',
                                    '&.Mui-checked': {
                                        color: hasError ? 'error.main' : 'primary.main',
                                    },
                                }}
                                inputProps={{
                                    'aria-describedby': hasError ? `${fieldId}-error` : undefined,
                                }}
                            />
                        }
                    />

                    {hasError && (
                        <FormHelperText
                            error
                            id={`${fieldId}-error`}
                            sx={{ ml: 2 }}
                        >
                            {errorMessage}
                        </FormHelperText>
                    )}
                </FormControl>
            )}
        />
    );
}
