import { useEffect, useState } from 'react';
import i18n from '../../i18n';
import { Select, FormControl, MenuItem, SelectChangeEvent, Skeleton } from '@mui/material';
import sweden from './sweden.png';
import unKi from './unKi.png';
import german from './german.png';
import spain from './spain.png';
import french from './french.png';
import norway from './norway.png';
import denmark from './denmark.png';
import netherlands from './netherlands.png';
import LocalStorageUtil from '../../utils/localStorage.util';
import { ImageWithSkeleton, skeletonShapeEnum } from '../elements/ImageWithSkeleton';
// Additional imports...

interface Props {
    selectedLanguage: (language: string) => void;
    languageValue?: string;
    languageToShowInBookingView: string[];
}

const LanguageSelector = ({ languageValue, selectedLanguage, languageToShowInBookingView }: Props) => {
    const [isLoading, setIsLoading] = useState(true);
    const onLoadImage = () => setIsLoading(false);
    const getInitialLanguage = () => {
        // Fetch language from local storage
        const localStorageLanguage = LocalStorageUtil.getItem('user-language-selected');
        if (localStorageLanguage && languageToShowInBookingView.includes(localStorageLanguage)) {
            return localStorageLanguage;
        }

        // Fetch and simplify the browser language
        const browserLanguage = navigator.language.split('-')[0];

        // Default to English if available, otherwise the first language in the list
        let initialLanguage = languageToShowInBookingView.includes('en') ? 'en' : languageToShowInBookingView[0];

        // Check if the browser language is acceptable
        if (languageToShowInBookingView.includes(browserLanguage)) {
            initialLanguage = browserLanguage;
        }

        return initialLanguage;
    };
    const [selectedLanguageKey, setSelectedLanguageKey] = useState<string>(languageValue || getInitialLanguage());

    useEffect(() => {
        const newLanguage = getInitialLanguage();
        // Check if the newLanguage is different from the current i18n language
        if (i18n.language !== newLanguage) {
            i18n.changeLanguage(newLanguage).then(() => {
                // Update the local storage and dispatch event if you're using custom events for storage changes
                localStorage.setItem('user-language-selected', newLanguage);
                window.dispatchEvent(
                    new CustomEvent('customStorageChange', {
                        detail: {
                            key: 'user-language-selected',
                            newValue: newLanguage
                        }
                    })
                );
            });
        } else if (localStorage.getItem('user-language-selected') !== newLanguage) {
            localStorage.setItem('user-language-selected', newLanguage);
        }

        setSelectedLanguageKey(newLanguage);
    }, [languageToShowInBookingView]);

    const handleChange = (event: SelectChangeEvent<string>) => {
        const newLanguage = event.target.value;
        setSelectedLanguageKey(newLanguage);
        selectedLanguage(newLanguage);
    };

    const languageSelection = [
        {
            key: 'en',
            name: 'english',
            icon: unKi
        },
        {
            key: 'sv',
            name: 'swedish',
            icon: sweden
        },
        {
            key: 'es',
            name: 'spain',
            icon: spain
        },
        {
            key: 'de',
            name: 'german',
            icon: german
        },
        {
            key: 'fr',
            name: 'french',
            icon: french
        },
        {
            key: 'no',
            name: 'norway',
            icon: norway
        },
        {
            key: 'da',
            name: 'denmark',
            icon: denmark
        },
        {
            key: 'nl',
            name: 'dutch',
            icon: netherlands
          }
    ];

    const filteredLanguages = languageSelection.filter((language) => languageToShowInBookingView.includes(language.key));

    return (
        <FormControl size={'small'}>
            <Select
                sx={{
                    boxShadow: 'none',
                    paddingTop: 0,
                    paddingBottom: 0,
                    '.MuiOutlinedInput-notchedOutline': { border: 'none' },
                    '.MuiSelect-select': {
                        paddingTop: 0,
                        paddingBottom: 0,
                        lineHeight: 0,
                        paddingRight: '12px !important'
                    }
                }}
                IconComponent={() => null}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedLanguageKey}
                onChange={handleChange}
                size={'small'}>
                {filteredLanguages.map((language) => (
                    <MenuItem value={language.key} key={language.key}>
                        <ImageWithSkeleton
                            skeletonShape={skeletonShapeEnum.CIRCULAR}
                            skeletonHeight={30}
                            skeletonWidth={30}
                            imgAlt={language.name}
                            imgSrc={language.icon}
                            imgStyle={{ width: 30 }}
                        />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default LanguageSelector;
