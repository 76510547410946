import { createContext } from 'react';
import { Site } from '../product/interfaces';

export const BookingFormContext = createContext<{
    showTermsAndConditions: boolean,
    setShowTermsAndConditions: Function,
    site: Site | null
}>({
    showTermsAndConditions: false,
    setShowTermsAndConditions: (value: boolean) => {},
    site: null
});
