import React, { useContext, useEffect, useState } from 'react';
import { Button, CircularProgress, Box } from '@mui/material';
import { AccountInformation, BookingItem, LockerItem } from './interface';
import axios from './../../utils/axios';
import { LockerTypeV1Alpha1, PinCodeResponse, Product, Site } from '../product/interfaces';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import CountdownTimer from './CountdownTimer';
import { getCurrentLanguageFromLocalStorage, translate } from '../../utils/languageTranslation.util';
import { ShowReturnContext } from '../return/ReturnContext';
import SkeletonUnlockFrame from '../skeletons/SkeletonUnlockFrame';
import BeforeOpenLockPage from '../beforeBooking/BeforeOpenLockPage';
import axiosInstance from './../../utils/axios';
import { ProductsList } from '../booking/productDisplayCard/ProductsList';

interface Props {
    bookingId: string;
    lockerItems: LockerItem[];
    pinCodesItems: PinCodeResponse[];
    setError: (error: boolean) => void;
    accountInformation: AccountInformation;
    bookingInfo: BookingItem;
    siteInformation: Site | null;
}

function LockInformation({ bookingId, lockerItems, setError, accountInformation, pinCodesItems, bookingInfo, siteInformation }: Props) {
    const { t } = useTranslation();
    const [isUnlockLoading, setIsUnlockLoading] = useState(false);
    const [unlocked8E, setUnlocked8E] = useState(false)
    const [has8ELock, setHas8ELock] = useState(false)
    const [earlyArrival, setEarlyArrival] = useState(false);
    const [lateArrival, setLateArrival] = useState(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [products, setProducts] = useState<Product[]>([]);
    const { setShowReturn } = useContext(ShowReturnContext);
    const [requireBeforeFlow, setRequireBeforeFlow] = useState<boolean>(true);
    const [loadingBeforeFlow, setLoadingBeforeFlow] = useState<boolean>(true);

    function resetData() {
        setLateArrival(false);
        setEarlyArrival(false);
        setError(false);
        setLoading(false)
    }

    const getAllProducts = (siteId) => {
        return axios.get(`product/v1/allProducts/${siteId}`);
    };

    //Ideally, this should also send a change to status or pickUpState on bookingInfo in database for 8E locks so the Unlock button doesn't appear if page is reloaded.
    async function unlockGear() {
        setIsUnlockLoading(true);
        let response: any;
        try {
            response = await axios.get(`booking/unlock/${bookingId}`);
            if (response.status === 200) {
                const data = response.data;
                setTimeout(() => {
                    if (data.lockerStatus === 'on_time') {
                        setLateArrival(false);
                        setEarlyArrival(false);
                        setUnlocked8E(true)
                    } else if (data.lockerStatus === 'late') {
                        setLateArrival(true);
                        setUnlocked8E(true)
                    } else if (data.lockerStatus === 'early') {
                        setEarlyArrival(true);
                        setUnlocked8E(false)
                    }
                    setIsUnlockLoading(false);
                    setTimeout(() => {
                        if (data.lockerStatus !== 'early') {
                            resetData();
                        }
                    }, 7000);
                }, 3000);
            } else {
                setError(true);
            }
        } catch (err: any) {
            console.error(err);
            setTimeout(() => {
                setError(true);
            }, 3000);
        }
    }

    async function verifyIglooBooking() {
        const response = await axios.get(`booking/verifyBooking/${bookingId}`);
        if (response.status === 200) {
            const data: any = await response.data;
            if (data.lockerStatus === 'on_time') {
                setLateArrival(false);
                setEarlyArrival(false);
            } else if (data.lockerStatus === 'late') {
                setLateArrival(true);
            } else if (data.lockerStatus === 'early') {
                setEarlyArrival(true);
            }
        }
    }

    const checkIfRequireBeforeFlow = async () => {
        setLoadingBeforeFlow(true);
        if (siteInformation?.beforeBookingSettings?.isEnabled) {
            const response = await axiosInstance.get(`customFields/v1/hasdata/${bookingId}/booking_before`);
            if (response.status >= 200 && response.status < 300) {
                setRequireBeforeFlow(!response.data);
            } else {
                setRequireBeforeFlow(false);
            }
        } else {
            setRequireBeforeFlow(false);
        }

        setLoadingBeforeFlow(false);
    };

    const getLockerItemsInfo = (lockerItems: LockerItem[]) => {
        if (
            lockerItems?.some(
                (lockerItem) => lockerItem?.type === LockerTypeV1Alpha1.IGLOO_PADLOCK || lockerItem.type === LockerTypeV1Alpha1.STORAGE_LOCK
            )
        ) {
            setLoading(true);
            verifyIglooBooking()
                .catch((err) => {
                    console.error(err);
                    setError(true);

                })
        }

        if (
            lockerItems?.some(
                (lockerItem) => lockerItem?.type === LockerTypeV1Alpha1.GOKAYA_8E)
        ) {
            setHas8ELock(true)
            setUnlocked8E(false)
        } else { setHas8ELock(false) }

        if (products.length === 0) {
            getAllProducts(bookingInfo.siteId).then((response) => {
                setProducts(response.data);
                setLoading(false);
            });
        } else {
            resetData();
        }
    };

    useEffect(() => {
        getLockerItemsInfo(lockerItems);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lockerItems]);

    useEffect(() => {
        checkIfRequireBeforeFlow();
    }, [siteInformation]);

    if (loading || loadingBeforeFlow) {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', flexDirection: 'column', width: '100%' }}>
                <SkeletonUnlockFrame />
            </Box>
        );
    }

    function ErrorMessage() {
        return (
            <div>
                {earlyArrival && !requireBeforeFlow && (
                    <>
                        <h2>{t('translation.unlock.earlyMessage')}</h2>
                        <p>
                            {t('translation.unlock.earlyMessage2', {
                                0: moment(bookingInfo.startDate).format(
                                    siteInformation?.account.timeSettingAmPm ? 'YYYY-MM-DD hh:mm A' : 'YYYY-MM-DD HH:mm'
                                )
                            })}
                        </p>
                        <Button
                            variant="contained"
                            sx={{ mb: 2, borderRadius: '80px', height: 40 }}
                            disabled={loading}
                            onClick={() => {
                                getLockerItemsInfo(lockerItems);
                            }}>
                            {t('translation.unlock.earlyMessageReload')}
                        </Button>
                    </>
                )}
                {/* This is mock of BeforeOpenLockPage questions */}
                {requireBeforeFlow && siteInformation?.beforeBookingSettings && (
                    <BeforeOpenLockPage
                        setRequireBeforeFlow={setRequireBeforeFlow}
                        beforeBookingSettings={siteInformation.beforeBookingSettings}
                        booking={bookingInfo}
                        lockerItems={lockerItems}
                        products={products}
                        pinCodesItems={pinCodesItems}
                        showProductImages={siteInformation.unlockDisplaySettings?.showImages}
                    />
                )}
                {lateArrival && <h2>{t('translation.unlock.notValid')}</h2>}
                <Box
                    dangerouslySetInnerHTML={{
                        __html: t('translation.unlock.questionMessage', {
                            0: siteInformation?.email ?? '',
                            1: siteInformation?.phone ?? ''
                        })
                    }}
                    sx={{
                        'a[href^="mailto:"]': {
                            color: '#004E31'
                        },
                        'a[href^="tel:"]': {
                            color: '#004E31'
                        },
                        marginBottom: '1rem'
                    }}></Box>
            </div>
        );
    }
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', flexDirection: 'column' }}>
            {!earlyArrival && !requireBeforeFlow && !lateArrival && siteInformation?.unlockDisplaySettings?.showHeading !== false && (
                <div>
                    <h2>
                        {siteInformation?.unlockDisplaySettings?.unlockHeadings
                            ? siteInformation.unlockDisplaySettings.unlockHeadings[getCurrentLanguageFromLocalStorage()]
                            : t('translation.unlock.unlockWelcome')}
                    </h2>
                </div>
            )}
            {earlyArrival || lateArrival || requireBeforeFlow ? (
                <ErrorMessage />
            ) : (
                <>
                    <ProductsList pinDataArray={pinCodesItems} sectionData={siteInformation?.unlockDisplaySettings!} lockerItems={lockerItems} products={products} bookingInfoProducts={bookingInfo.products} showPinCodes showImages={siteInformation?.unlockDisplaySettings?.showImages} />

                    {siteInformation?.unlockDisplaySettings?.showMessage !== false && (
                        <p style={{ marginBottom: '24px' }}>
                            {siteInformation?.unlockDisplaySettings?.unlockMessages
                                ? siteInformation.unlockDisplaySettings.unlockMessages[getCurrentLanguageFromLocalStorage()]
                                : t('translation.unlock.pickupInfoMessage')}
                        </p>
                    )}


                    {has8ELock && unlocked8E && <p style={{ color: '#004e31', fontWeight: 'bold' }}>{t('translation.unlock.productUnlocked')}</p>}
                    {has8ELock && !unlocked8E && <>
                        <Button
                            disabled={isUnlockLoading}
                            variant="contained"
                            onClick={unlockGear}
                            sx={{
                                borderRadius: '80px',
                                height: 40,
                                marginTop: '12px'
                            }}>
                            {isUnlockLoading ? (
                                <CircularProgress
                                    size={30}
                                    sx={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: '-16px',
                                        marginLeft: '-16px',
                                        color: 'white'
                                    }}
                                />
                            ) : (
                                t('translation.unlock.unlockProduct')
                            )}
                        </Button>
                        <p>{t('translation.unlock.unlockStandard')}</p>
                    </>}
                    {siteInformation?.unlockDisplaySettings?.showTimer !== false && (
                        <CountdownTimer targetDate={bookingInfo.endDate} timezone={siteInformation?.timezone} />
                    )}
                    {siteInformation?.unlockDisplaySettings?.showConfirmButton !== false && (
                        <Button
                            variant="outlined"
                            sx={{ borderRadius: '80px', height: 40, marginTop: '16px', color: 'black', borderColor: 'black', borderWidth: 2 }}
                            onClick={() => {
                                setShowReturn(true);
                            }}>
                            {t('translation.unlock.stopRent')}
                        </Button>
                    )}
                    <Box
                        dangerouslySetInnerHTML={{
                            __html: t('translation.unlock.questionMessage', {
                                0: siteInformation?.email,
                                1: siteInformation?.phone
                            })
                        }}
                        sx={{
                            'a[href^="mailto:"]': {
                                color: '#004E31'
                            },
                            'a[href^="tel:"]': {
                                color: '#004E31'
                            },
                            marginTop: '2rem',
                            marginBottom: '1rem'
                        }}></Box>
                </>
            )}
        </Box>
    );
}

export default LockInformation;
