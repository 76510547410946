import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Box, Button, Checkbox, FormControl, FormControlLabel, FormHelperText, Stack, Typography } from '@mui/material';
import { ICustomField } from '../product/interfaces';
import { fetchTranslation, getCurrentLanguageFromLocalStorage, LanguageTextTypeV1Alpha1 } from '../../utils/languageTranslation.util';
import { Control, Controller } from 'react-hook-form';
import SignatureCanvas from 'react-signature-canvas';
import './css/customFieldVideoShow.scss';
import { useTranslation } from 'react-i18next';

interface CustomFieldVideoShowProps {
  customField: ICustomField;
  onChange: Function;
  errors: Record<string, any>;
  control: Control<any>;
}

export default function CustomFieldVideoShow({ customField, onChange, errors, control }: CustomFieldVideoShowProps) {
  const { t } = useTranslation();
  const sigCanvas = useRef<SignatureCanvas>(null);

  // Extract video URL and options with proper typing
  const videoConfig = useMemo(() => {
    if (!customField?.options) return { embedHtml: '', autoplay: false };

    const embedUrlOption = customField.options.find(o => o.id === 'embedUrl');
    const autoplayOption = customField.options.find(o => o.id === 'autoplay');
    const autoplay = autoplayOption?.value ?? false;

    const videoUrl = embedUrlOption ? fetchTranslation(embedUrlOption.value as LanguageTextTypeV1Alpha1) : '';

    // Extract YouTube ID and create embed URL with proper parameters
    const youtubeRegex = /(?:https:\/\/)?(?:http:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?(.+)/;
    const match = videoUrl.match(youtubeRegex);
    const youtubeId = match ? match[1] : '';

    const autoplayParams = autoplay ? '?&autoplay=1&mute=1' : '';
    const embedHtml = youtubeId ?
      `<iframe width="100%" style="aspect-ratio: 16 / 9;" src="https://www.youtube.com/embed/${youtubeId}${autoplayParams}" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>`
      : '';

    return { embedHtml, autoplay };
  }, [customField?.options]);

  // Get signature text
  const signatureText = useMemo(() => {
    if (!customField?.options) return '';
    const option = customField.options.find(o => o.id === 'signatureText');
    return option ? fetchTranslation(option.value as LanguageTextTypeV1Alpha1) : '';
  }, [customField?.options]);

  const useSignature = customField?.options?.find(o => o.id === 'confirmationMethod')?.value === 'SIGNATURE';
  const isMandatory = customField?.isMandatory;

  // Clear signature handler
  const clearSignature = () => {
    if (sigCanvas.current) {
      sigCanvas.current.clear();
    }
  };

  return (
    <Controller
      name={customField.id}
      control={control}
      defaultValue=""
      rules={{
        required: customField.isMandatory,
        validate: value => {
          // Custom validation for signature or checkbox
          if (customField.isMandatory && !value) {
            return `${fetchTranslation(customField.name)} is required`;
          }
          return true;
        }
      }}
      render={({ field }) => (
        <FormControl
          fullWidth
          error={Boolean(errors[customField.id])}
          sx={{ mb: 2 }}
        >
          <Typography variant="subtitle1" gutterBottom>
            {fetchTranslation(customField.name)}
            {customField.isMandatory && <span style={{ color: 'red' }}> *</span>}
          </Typography>

          {/* Video display */}
          {videoConfig.embedHtml && (
            <div
              className="video-container"
              dangerouslySetInnerHTML={{ __html: videoConfig.embedHtml }}
              style={{ marginBottom: '1rem' }}
            />
          )}

          {/* Signature pad */}
          {(isMandatory && useSignature && signatureText) && (
            <Stack spacing={2}>
              <Typography variant="body2">{signatureText}</Typography>
              <div
                style={{
                  border: `1px solid ${errors[customField.id] ? 'red' : '#ccc'}`,
                  backgroundColor: '#f8f8f8',
                  borderRadius: '4px'
                }}
              >
                <SignatureCanvas
                  ref={sigCanvas}
                  canvasProps={{
                    height: 150,
                    className: 'signature-canvas'
                  }}
                  onEnd={(event) => {
                    const signatureData = sigCanvas.current?.isEmpty() ? '' : sigCanvas.current?.toDataURL();
                    field.onChange(signatureData);
                    onChange(customField, signatureData || '');
                  }}
                />
              </div>
              <Stack direction="row" justifyContent="flex-end">
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => {
                      clearSignature();
                      field.onChange('');
                      onChange(customField, '');
                    }}
                    sx={{ minWidth: '80px' }}
                  >
                    {t('translation.beforeUnlock.clear')}
                  </Button>
                </Box>
              </Stack>
            </Stack>
          )}

          {/* Confirmation checkbox (if no signature) */}
          {isMandatory && !useSignature && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(field.value)}
                  onChange={(e) => {
                    field.onChange(e.target.checked);
                    onChange(customField, e.target.checked);
                  }}
                />
              }
              label={signatureText}
            />
          )}

          {errors[customField.id] && (
            <FormHelperText error>
              {errors[customField.id].message || `${fetchTranslation(customField.name)} is required`}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
}
