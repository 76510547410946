/* eslint-disable react/no-array-index-key */
import React, { useMemo, useState, useCallback, useEffect, useContext } from 'react';
import {
    Button,
    Typography,
    Box,
    AppBar,
    IconButton,
    Grid,
    Stack,
    Chip,
    OutlinedInput,
    CircularProgress,
    Select,
    InputLabel,
    MenuItem,
    FormControl,
    Checkbox,
    FormHelperText,
    Theme
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { useForm, Controller } from 'react-hook-form';
import MuiPhoneNumber from 'material-ui-phone-number';
import parsePhoneNumber from 'libphonenumber-js';
import useFormPersist from 'react-hook-form-persist';
import axios from '../../utils/axios';
import {
    BookingReceipt,
    BookingPaymentTypes,
    ValidatedCampaignCode,
    BookingPaymentProviders,
    BookableEventV1,
    EventV1,
    Site,
    CreateSessionResponse
} from '../product/interfaces';
// import ProductSummary from '../product/ProductSummary';
import TermsAndConditionDialog from '../product/TermsAndConditionDialog';
import EventSummary from '../event/EventSummary';
import Footer from '../footer/Footer';
import TagManager from 'react-gtm-module';
import countries from '../../data/countriesData.json';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { getCurrentLanguageFromLocalStorage } from '../../utils/languageTranslation.util';
import { BookingFormContext } from './BookingFormContext';

interface EventCartSessionProps {
    siteId;
    totalCost;
    totalVAT;
    totalDiscount;
    firstName;
    lastName;
    phoneNumber;
    mail;
    consentToEmailMarketing;
    bookableEvents: Record<string, { attendees: number; info: string | undefined } | { attendees: Record<string, number>; info: string | undefined }>;
    insurances;
    // channel;
    paymentProvider?: number;
    paymentType;
    paymentStatus?: number;
    extraInfo?;
    returnurl;
    //cancelurl;
    promoCode?;
    timeSettingAmPm?: boolean;
    country?;
    city?;
    address?;
    zipCode?;
    languageCode?;
}

interface Props {
    selectedEvents: BookableEventV1[];
    setSelectedEvents: (eventOccations: BookableEventV1[]) => void;
    goToPrevStep: () => void;
    goToNextStep: () => void;
    siteId: string;
    setReceipt: (receipt: BookingReceipt) => void;
    goToFirstStep: () => void;
    bookingType: BookingPaymentProviders;
    initialSite: string;
    currencyISO: string;
    availableEvents: EventV1[];
    siteData: Site | null;
    goToReceiptStep: () => void;
    setClientSecret: (clientSecret: string) => void;
    setPublicStripeKey: (publicStripeKey: string) => void;
    setSessionExpiryTime: (expiryTime: string) => void;
    setOrderId: (orderId: string) => void;
    initialCheckoutErrorMessage: string;
    setInitialCheckoutErrorMessage: (initialCheckoutErrorMessage: string) => void;
    setOpenLockerCodes: (openLockerCodes: boolean) => void;
}

const EventCartStep = ({
    selectedEvents,
    setSelectedEvents,
    goToPrevStep,
    goToNextStep,
    goToFirstStep,
    siteId,
    setReceipt,
    bookingType,
    initialSite,
    currencyISO,
    availableEvents,
    siteData,
    goToReceiptStep,
    setClientSecret,
    setPublicStripeKey,
    setSessionExpiryTime,
    setOrderId,
    initialCheckoutErrorMessage,
    setInitialCheckoutErrorMessage,
    setOpenLockerCodes
}: Props) => {
    const [campaignCode, setCampaignCode] = useState('');
    const [paymentType, setPaymentType] = useState(-1);
    const [paymentStatus, setPaymentStatus] = useState(-1);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [validatedCampaignCode, setValidatedCampaignCode] = useState<ValidatedCampaignCode | null>(null);
    const [showCampaignCodeError, setShowCampaignCodeError] = useState(false);
    const [acceptPolicy, setAcceptPolicy] = useState(false);
    const [emailMarketing, setEmailMarketing] = useState(false);
    const [loadingPromo, setLoadingPromo] = useState(false);
    const [loadingBook, setLoadingBook] = useState(false);
    const [totalDiscount, setTotalDiscount] = useState(0);
    const [cartFields, setCartFields] = useState<any[]>([]);
    const [errorMessage, setErrorMessage] = useState('');
    const { setShowTermsAndConditions } = useContext(BookingFormContext);
    const { t } = useTranslation();

    const siteCountryCode = useMemo(() => {
        return countries.countriesData.find((country) => country.labelEn === siteData?.country)?.key ?? 'SE';
    }, [siteData?.country]);

    const generateValidationSchema = (cartSettings) => {
        let schemaFields = {
            phoneNumber: yup
                .string()
                .test(t('translation.cartStep.phoneInvalid'), t('translation.cartStep.invalidPhone'), (v) => {
                    if (!v) return false;
                    const phoneNumber = parsePhoneNumber(v);
                    return phoneNumber ? phoneNumber.isPossible() : false;
                })
                .label('Phone Number'),
            firstName: yup.string().required(t('translation.cartStep.firstNameRequired')),
            lastName: yup.string().required(t('translation.cartStep.lastNameRequired')),
            email: yup.string().email(t('translation.cartStep.emailInvalid')).required(t('translation.cartStep.emailRequired'))
        };

        if (cartSettings?.address?.active && cartSettings?.address?.mandatory) {
            schemaFields['address'] = yup.string().required(t('translation.cartStep.addressRequired'));
        }

        if (cartSettings?.zipCode?.active && cartSettings?.zipCode?.mandatory) {
            schemaFields['zipCode'] = yup.string().required(t('translation.cartStep.zipCodeRequired'));
        }

        if (cartSettings?.city?.active && cartSettings?.city?.mandatory) {
            schemaFields['city'] = yup.string().required(t('translation.cartStep.cityRequired'));
        }

        if (cartSettings?.country?.active && cartSettings?.country?.mandatory) {
            schemaFields['country'] = yup.string().required(t('translation.cartStep.countryRequired'));
        }

        return yup.object().shape(schemaFields);
    };
    const formSchema = generateValidationSchema(siteData?.cartSettings);

    const {
        handleSubmit,
        control,
        register,
        getValues,
        watch,
        setValue,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(formSchema),
        mode: 'all'
    });

    useFormPersist('form', { watch, setValue, storage: window.localStorage });

    const totalValue = useMemo(() => {
        let value = 0;

        selectedEvents.forEach((eventOccation) => {
            // Product
            value += eventOccation.cost * eventOccation.quantity;
        });

        if (validatedCampaignCode && validatedCampaignCode.percentDiscount > 0) {
            const fullPrice = value;
            value *= (100 - validatedCampaignCode.percentDiscount) / 100;
            setTotalDiscount(parseFloat((fullPrice - value).toFixed(2)));
        } else {
            setTotalDiscount(0);
        }
        value = parseFloat(value.toFixed(2));
        return value;
    }, [selectedEvents, validatedCampaignCode]);

    // const getTaxAmount = (taxRate: string, price: number) => {
    //     let tax = 1.25;
    //     switch (taxRate) {
    //         case '0.25':
    //             tax = 1.25;
    //             break;
    //         case '0.12':
    //             tax = 1.12;
    //             break;
    //         case '0.06':
    //             tax = 1.06;
    //             break;
    //         case '0':
    //             tax = 1.0;
    //             break;
    //         default:
    //             tax = 1.25;
    //             break;
    //     }

    //     const priceWithoutTax = price / tax;
    //     return parseFloat((price - priceWithoutTax).toFixed(2));
    // };

    const isValidVAT = (vat: number): boolean => {
        return vat >= 0 && vat <= 1;
    };

    const getTaxAmount = (taxRate: number, price: number) => {
        if (!isValidVAT(taxRate)) {
            return 0;
        }
        const priceWithoutTax = price / (1 + taxRate);
        return parseFloat((price - priceWithoutTax).toFixed(2));
    };

    const totalVATAmount = useMemo(() => {
        const vatAmount = selectedEvents.map((event) => {
            let eventCost = event.cost * event.quantity;

            // Campaign
            if (validatedCampaignCode && validatedCampaignCode.percentDiscount > 0) {
                eventCost *= (100 - validatedCampaignCode.percentDiscount) / 100;
            }

            let eventVAT = getTaxAmount(event.vat, eventCost); // Removed toString() conversion
            return eventVAT;
        });

        return vatAmount.length > 0 ? vatAmount.reduce((partialSum, a) => partialSum + a, 0).toFixed(2) : 0;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedEvents, validatedCampaignCode]);

    const bookableEventObjects = useMemo(() => {
        const eventsObject: Record<
            string,
            { attendees: number; info: string | undefined } | { attendees: Record<string, number>; info: string | undefined }
        > = {};
        selectedEvents.forEach((event) => {
            const priceGroupString = event.priceGroup as string;
            if (priceGroupString) {
                // Variant price with specific attendees for each option
                if (!eventsObject[event.id]) {
                    eventsObject[event.id] = {
                        attendees: {
                            [priceGroupString]: event.quantity
                        },
                        info: event.info
                    };
                } else {
                    // If the bookableEventId already exists, add this priceGroup and attendees
                    eventsObject[event.id].attendees[priceGroupString] = event.quantity;
                }
            } else {
                // Simple price with just the number of attendees
                if (!eventsObject[event.id]) {
                    eventsObject[priceGroupString ? priceGroupString : event.id] = {
                        attendees: event.quantity,
                        info: event.info
                    };
                } else {
                    // If the bookableEventId already exists, add this as a default priceGroup
                    eventsObject[event.id].attendees['defaultPriceGroup'] = Number(event.quantity);
                }
            }
        });

        return eventsObject;
    }, [selectedEvents]);

    const onClickBook = useCallback(async () => {
        try {
            setLoadingBook(true);

            // Parse the initial URL
            const url = new URL(initialSite);
            // Use URLSearchParams to add the new parameter
            url.searchParams.append('showConfirm', 'true');
            // The updated URL
            const initialSiteUrl = url.toString();

            const objectToSend: EventCartSessionProps = {
                siteId: siteId,
                totalCost: totalValue,
                totalVAT: totalVATAmount,
                totalDiscount: totalDiscount,
                firstName: getValues('firstName'),
                lastName: getValues('lastName'),
                mail: getValues('email'),
                phoneNumber: getValues('phoneNumber'),
                languageCode: getCurrentLanguageFromLocalStorage() || 'en',
                consentToEmailMarketing: emailMarketing,
                bookableEvents: bookableEventObjects,
                insurances: [],
                paymentType: paymentType,
                returnurl: initialSiteUrl,
                //cancelurl: initialSite, // ToDo: decide how to handle cancel in the future, for now only return customer to previous page.
                paymentProvider: 1,
                paymentStatus
            };
            if (getValues('country')) {
                objectToSend.country = getValues('country');
            }
            if (getValues('city')) {
                objectToSend.city = getValues('city');
            }
            if (getValues('address')) {
                objectToSend.address = getValues('address');
            }
            if (getValues('zipCode')) {
                objectToSend.zipCode = getValues('zipCode');
            }
            if (getValues('extraInfo')) {
                objectToSend.extraInfo = getValues('extraInfo');
            }

            if (validatedCampaignCode) {
                objectToSend.promoCode = validatedCampaignCode ? validatedCampaignCode.code : '';
            }
            const response = await axios.post('/events/v1/checkout', objectToSend);
            const result = response.data as BookingReceipt;
            setLoadingBook(false);
            setReceipt(result);
            goToReceiptStep();
        } catch (e) {
            setLoadingBook(false);
            console.log(e);
        }
        // reset();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        getValues,
        siteId,
        phoneNumber,
        totalValue,
        validatedCampaignCode,
        emailMarketing,
        paymentType,
        paymentStatus,
        totalDiscount,
        totalVATAmount,
        initialSite
    ]);

    const goToPayment = useCallback(async () => {
        try {
            setLoadingBook(true);
            const objectToSend: EventCartSessionProps = {
                siteId: siteId,
                totalCost: totalValue,
                totalVAT: totalVATAmount,
                totalDiscount: totalDiscount,
                firstName: getValues('firstName'),
                lastName: getValues('lastName'),
                mail: getValues('email'),
                phoneNumber: getValues('phoneNumber'),
                languageCode: getCurrentLanguageFromLocalStorage() || 'en',
                consentToEmailMarketing: emailMarketing,
                bookableEvents: bookableEventObjects,
                insurances: [],
                paymentType: 2, // Card
                returnurl: initialSite + '?showConfirm=true'
                //cancelurl: initialSite // ToDo: decide how to handle cancel in the future, for now only return customer to previous page.
            };

            if (getValues('country')) {
                objectToSend.country = getValues('country');
            }
            if (getValues('city')) {
                objectToSend.city = getValues('city');
            }
            if (getValues('address')) {
                objectToSend.address = getValues('address');
            }
            if (getValues('zipCode')) {
                objectToSend.zipCode = getValues('zipCode');
            }
            if (getValues('extraInfo')) {
                objectToSend.extraInfo = getValues('extraInfo');
            }

            if (validatedCampaignCode) {
                objectToSend.promoCode = validatedCampaignCode ? validatedCampaignCode.code : '';
            }

            const response = await axios.post('/events/v1/stripe/createSession', objectToSend);
            setLoadingBook(false);
            const result = response.data as CreateSessionResponse;
            if (result.url) {
                if (window.parent) {
                    window.parent.postMessage({ command: 'gokaya-RedirectToStripe', detail: { url: result.url, expireUrl: result.url } }, '*');
                } else {
                    window.postMessage({ command: 'gokaya-RedirectToStripe', detail: { url: result.url, expireUrl: result.url } }, '*');
                }
            } else {
                setClientSecret(result.clientSecret);
                setPublicStripeKey(result.publicStripeKey);
                setSessionExpiryTime(result.sessionExpiryTime);
                setOrderId(result.orderId);
                setOpenLockerCodes(result.openNow);
                goToNextStep();
            }
        } catch (e) {
            setLoadingBook(false);
            setErrorMessage(t('translation.cartStep.outOfStock'));
            setInitialCheckoutErrorMessage('');
            console.log(e);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        getValues,
        siteId,
        phoneNumber,
        totalValue,
        validatedCampaignCode,
        emailMarketing,
        paymentType,
        paymentStatus,
        totalDiscount,
        totalVATAmount,
        initialSite
    ]);

    const hasProductsInCart = useCallback(() => selectedEvents.length > 0, [selectedEvents]);

    const removeAllProducts = () => {
        setSelectedEvents([]);
    };

    useEffect(() => {
        const data = localStorage.getItem('phoneNumber');
        if (data) {
            setPhoneNumber(JSON.parse(data));
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('phoneNumber', JSON.stringify(phoneNumber));
    });

    useEffect(() => {
        if (!hasProductsInCart()) {
            removeAllProducts();
            goToFirstStep();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedEvents]);

    const validateCampaignCode = useCallback(async () => {
        if (!campaignCode) {
            return;
        }
        try {
            setLoadingPromo(true);
            const response = await axios.get(`stripe/v1/promotionCodeValid/${campaignCode}/${siteId}`)
            console.log('API response i eventcart:', response.data);
            setLoadingPromo(false);
            if (response.data) {
                setValidatedCampaignCode(response.data as ValidatedCampaignCode);
                setCampaignCode('');
                setShowCampaignCodeError(false);
            }
        } catch (e) {
            console.log(e);
            setLoadingPromo(false);
            setShowCampaignCodeError(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campaignCode]);

    useEffect(() => {
        const fields = [
            {
                name: 'firstName',
                order: siteData?.cartSettings?.name?.order || 0,
                required: siteData?.cartSettings?.name?.mandatory || true,
                active: siteData?.cartSettings?.name?.active || true
            },
            {
                name: 'lastName',
                order: siteData?.cartSettings?.surname?.order || 0,
                required: siteData?.cartSettings?.surname?.mandatory || true,
                active: siteData?.cartSettings?.surname?.active || true
            },
            {
                name: 'email',
                order: siteData?.cartSettings?.email?.order || 0,
                required: siteData?.cartSettings?.email?.mandatory || true,
                active: siteData?.cartSettings?.email?.active || true,
                pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
            },
            {
                name: 'phoneNumber',
                order: siteData?.cartSettings?.phone?.order || 0,
                required: siteData?.cartSettings?.phone?.mandatory || true,
                active: siteData?.cartSettings?.phone?.active || true,
                customControl: (
                    <FormControl variant="outlined">
                        <Controller
                            name="phoneNumber"
                            control={control}
                            render={({ field }) => (
                                <MuiPhoneNumber
                                    {...field}
                                    variant="outlined"
                                    size="small"
                                    error={!!errors.phoneNumber}
                                    helperText={errors.phoneNumber?.message}
                                    label={t('translation.cartStep.phoneNumber')}
                                    data-cy="user-phone"
                                    defaultCountry={getValues('phoneNumber') ? undefined : siteCountryCode}
                                />
                            )}
                        />
                    </FormControl>
                )
            },
            {
                name: 'address',
                order: siteData?.cartSettings?.address?.order || 0,
                required: siteData?.cartSettings?.address?.mandatory || true,
                active: siteData?.cartSettings?.address?.active || false
            },
            {
                name: 'zipCode',
                order: siteData?.cartSettings?.zipCode?.order || 0,
                required: siteData?.cartSettings?.zipCode?.mandatory || true,
                active: siteData?.cartSettings?.zipCode?.active || false
            },
            {
                name: 'city',
                order: siteData?.cartSettings?.city?.order || 0,
                required: siteData?.cartSettings?.city?.mandatory || true,
                active: siteData?.cartSettings?.city?.active || false
            },
            {
                name: 'country',
                order: siteData?.cartSettings?.country?.order || 0,
                required: siteData?.cartSettings?.country?.mandatory || true,
                active: siteData?.cartSettings?.country?.active || false,
                customControl: (
                    <FormControl variant="outlined" size="small" fullWidth>
                        <Controller
                            name="country"
                            control={control}
                            defaultValue="" // Explicitly set the default value to an empty string
                            render={({ field }) => (
                                <>
                                    <InputLabel id="country-label">{t('translation.cartStep.country')}</InputLabel>
                                    <Select
                                        {...field}
                                        labelId="country-label"
                                        label={t('translation.cartStep.country')}
                                        error={!!errors.country}
                                        displayEmpty
                                        data-cy="user-country">
                                        {countries.countriesData.map((country) => {
                                            const label = getCurrentLanguageFromLocalStorage() === 'sv' ? country.labelSv : country.labelEn;
                                            const value = country.key;
                                            return (
                                                <MenuItem key={label} value={value}>
                                                    {label}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </>
                            )}
                        />
                    </FormControl>
                )
            }
        ];
        const fieldsInOrder = fields.sort((a, b) => a.order - b.order);
        setCartFields(fieldsInOrder);
    }, [siteData, control, t, errors.phoneNumber, errors.country]);

    useEffect(() => {
        if (typeof window.gtag === 'function') {
            window.gtag('event', 'page_view', {
                page_title: 'Event Cart Page'
            });
        }
    }, []);

    return (
        <>
            <Box sx={{ p: 2, pb: 0, position: 'relative', display: 'flex', flexDirection: 'column', height: '100%' }}>
                {initialCheckoutErrorMessage && <p style={{ color: 'red', textAlign: 'center' }}>{initialCheckoutErrorMessage}</p>}
                {errorMessage && <p style={{ color: 'red', textAlign: 'center' }}>{errorMessage}</p>}
                <Box sx={{ flexGrow: 1 }}>
                    <EventSummary
                        selectedEvents={selectedEvents}
                        setSelectedEvents={setSelectedEvents}
                        currencyISO={currencyISO}
                        availableEvents={availableEvents}
                        timeSettingAmPm={siteData ? siteData.account.timeSettingAmPm : false}
                    />
                    <Box sx={{ mb: 1 }}>
                        {totalDiscount > 0 && (
                            <Box sx={{ display: 'flex' }}>
                                <Typography sx={{ flex: 1, textAlign: 'right', marginRight: '24px' }}>
                                    {t('translation.cartStep.discount')}:
                                </Typography>
                                <Typography sx={{ textAlign: 'right', color: '#f20000' }}>
                                    {totalDiscount}
                                    {currencyISO}
                                </Typography>
                            </Box>
                        )}
                        <Box sx={{ display: 'flex' }}>
                            <Typography sx={{ flex: 1, textAlign: 'right', marginRight: '24px' }}>{t('translation.cartStep.vat')}:</Typography>
                            <Typography sx={{ textAlign: 'right' }}>
                                {totalVATAmount}
                                {currencyISO}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex' }}>
                            <Typography sx={{ flex: 1, textAlign: 'right', marginRight: '24px' }}>{t('translation.cartStep.total')}:</Typography>
                            <Typography sx={{ textAlign: 'right' }}>
                                {totalValue}
                                {currencyISO}
                            </Typography>
                        </Box>
                    </Box>
                    <Grid item xs={12} md={12}>
                        <Stack spacing={1}>
                            <Stack direction="row" spacing={1}>
                                <FormControl variant="outlined" sx={{ flex: 1 }}>
                                    <OutlinedInput
                                        size="small"
                                        value={campaignCode}
                                        placeholder={t('translation.cartStep.campaignCode')}
                                        onChange={(e) => setCampaignCode(e.target.value)}
                                        sx={{ borderColor: '#004E31', borderRadius: 1 }}
                                        disabled={loadingPromo || validatedCampaignCode !== null}
                                    />
                                    {showCampaignCodeError && (
                                        <Typography variant="subtitle2" sx={{ color: '#cc0000' }}>
                                            {t('translation.cartStep.campaignCodeAlert')}
                                        </Typography>
                                    )}
                                </FormControl>
                                <Button
                                    variant="outlined"
                                    disableElevation
                                    sx={{ borderRadius: 1, height: 40, width: '90px' }}
                                    onClick={validateCampaignCode}
                                    disabled={loadingPromo || validatedCampaignCode !== null}>
                                    {loadingPromo ? (
                                        <CircularProgress color="primary" size="small" sx={{ width: '20px' }} />
                                    ) : (
                                        t('translation.cartStep.validateButton')
                                    )}
                                </Button>
                            </Stack>
                            {validatedCampaignCode && (
                                <Stack direction="row" spacing={2}>
                                    <Chip label={validatedCampaignCode.code} sx={{ borderRadius: 0, py: '2px', height: 'auto' }} />
                                    <Typography
                                        variant="subtitle1"
                                        sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                                        onClick={() => setValidatedCampaignCode(null)}>
                                        {t('translation.cartStep.removeCode')}
                                    </Typography>
                                </Stack>
                            )}
                            <Typography variant="body1">{t('translation.cartStep.customerDetails')}</Typography>
                            <style type="text/css">
                                {'#country-menu { z-index: 2147483647;} .MuiPhoneNumber-flagButton { max-width: 30px; max-height: 30px;}'}
                            </style>
                            <form>
                                <Stack spacing={1}>
                                    {cartFields
                                        .filter((f) => f.active)
                                        .map((field) => {
                                            if (field.customControl) {
                                                return field.customControl;
                                            } else {
                                                return (
                                                    <FormControl
                                                        variant="outlined"
                                                        key={field.name}
                                                        fullWidth
                                                        margin="dense"
                                                        error={Boolean(errors[field.name])}>
                                                        <OutlinedInput
                                                            size="small"
                                                            placeholder={t(`translation.cartStep.${field.name}`)}
                                                            sx={{ borderColor: '#004E31', borderRadius: 1 }}
                                                            {...register(field.name, { required: field.required })}
                                                            error={Boolean(errors[field.name])}
                                                        />
                                                        {errors[field.name] && <FormHelperText error>{errors[field.name]?.message}</FormHelperText>}
                                                    </FormControl>
                                                );
                                            }
                                        })}
                                    <FormControl variant="outlined">
                                        <OutlinedInput
                                            size="small"
                                            placeholder={t('translation.cartStep.extraInfoText')}
                                            {...register('extraInfo', { required: false })}
                                            multiline={true}
                                        />
                                    </FormControl>
                                    {bookingType === BookingPaymentProviders.MANUAL && (
                                        <>
                                            <Typography variant="body1">{t('translation.cartStep.payment')}</Typography>
                                            <FormControl variant="outlined" size="small" sx={{ marginTop: '0 !important;' }}>
                                                <InputLabel id="payment-type-label">{t('translation.cartStep.paymentType')}</InputLabel>
                                                <Select
                                                    labelId="payment-type-label"
                                                    id="payment-type-select"
                                                    value={paymentType}
                                                    variant="outlined"
                                                    label={t('translation.cartStep.paymentType')}
                                                    onChange={(event) => {
                                                        setPaymentType(event.target.value as number);
                                                    }}>
                                                    {Object.keys(BookingPaymentTypes)
                                                        .filter((key: any) => isNaN(Number(BookingPaymentTypes[key])))
                                                        .map((item: any, index) => {
                                                            return (
                                                                <MenuItem key={item} value={Number(item)}>
                                                                    {BookingPaymentTypes[item]}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                </Select>
                                            </FormControl>
                                            <FormControl variant="outlined" size="small">
                                                <InputLabel id="payment-status-label">{t('translation.cartStep.paymentStatus')}</InputLabel>
                                                <Select
                                                    labelId="payment-status-label"
                                                    id="payment-status-select"
                                                    value={paymentStatus !== -1 ? paymentStatus : ''}
                                                    variant="outlined"
                                                    label={t('translation.cartStep.paymentStatus')}
                                                    onChange={(event) => {
                                                        setPaymentStatus(event.target.value as number);
                                                    }}>
                                                    <MenuItem value={0}>Unpaid</MenuItem>
                                                    <MenuItem value={1}>Paid</MenuItem>
                                                    <MenuItem value={3}>Partial paid</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </>
                                    )}
                                    <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                                        {/* <CheckBox selected={acceptPolicy} onChange={setAcceptPolicy} /> */}
                                        <Checkbox
                                            checked={acceptPolicy}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setAcceptPolicy(event.target.checked)}
                                        />
                                        <Typography variant="subtitle1">
                                            {t('translation.cartStep.termsAndConditions1')}
                                            <Typography
                                                sx={{
                                                    textDecoration: 'underline',
                                                    cursor: 'pointer',
                                                    color: '#004E31',
                                                    display: 'inline-block'
                                                }}
                                                onClick={() => setShowTermsAndConditions(true)}
                                                variant="body1">
                                                {t('translation.cartStep.termsAndConditions2')}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    color: 'red',
                                                    display: 'inline'
                                                }}
                                                onClick={() => setShowTermsAndConditions(true)}
                                                variant="body1">
                                                *
                                            </Typography>
                                        </Typography>
                                    </Stack>
                                    <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                                        {/* <CheckBox selected={emailMarketing} onChange={setEmailMarketing} /> */}
                                        <Checkbox
                                            checked={emailMarketing}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEmailMarketing(event.target.checked)}
                                        />
                                        <Typography variant="subtitle1">
                                            {t('translation.cartStep.marketingEmail')} {siteData?.name}.
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </form>
                        </Stack>
                    </Grid>
                </Box>
                <AppBar
                    position="sticky"
                    color="transparent"
                    sx={{
                        top: 'auto',
                        bottom: 0,
                        textAlign: 'center',
                        py: 1,
                        boxShadow: 'none',
                        backgroundColor: '#ffffff',
                        zIndex: 9999
                    }}>
                    <Box sx={{ display: 'flex' }}>
                        <IconButton
                            color="secondary"
                            onClick={() => {
                                goToPrevStep();
                            }}>
                            <ArrowBackIosNewRoundedIcon />
                        </IconButton>
                        <Button
                            variant="contained"
                            disableElevation
                            sx={{ borderRadius: 20, height: 40, px: 4, flex: 1, mr: 2 }}
                            onClick={bookingType === BookingPaymentProviders.MANUAL ? handleSubmit(onClickBook) : handleSubmit(goToPayment)}
                            disabled={
                                loadingBook ||
                                !acceptPolicy ||
                                (bookingType === BookingPaymentProviders.MANUAL ? paymentType === -1 || paymentStatus === -1 : false)
                            }>
                            {loadingBook ? (
                                <CircularProgress size="small" sx={{ width: '20px' }} />
                            ) : bookingType === BookingPaymentProviders.MANUAL || !!siteData?.account.disableStripePayments ? (
                                t('translation.cartStep.bookButton')
                            ) : (
                                t('translation.cartStep.payButton')
                            )}
                        </Button>
                    </Box>
                    <Footer />
                </AppBar>
            </Box>
        </>
    );
};

export default EventCartStep;
