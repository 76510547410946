import React, { useEffect, useState } from 'react';
import { FormControl, FormHelperText, InputLabel, MenuItem, OutlinedInput, Rating, Select, SelectChangeEvent } from '@mui/material';
import { ICustomField } from '../product/interfaces';
import { fetchTranslation, getCurrentLanguageFromLocalStorage } from '../../utils/languageTranslation.util';
import { Control, Controller } from 'react-hook-form';
import StarOutlineIcon from '@mui/icons-material/StarOutline';

interface CustomFieldRatingProps {
  customField: ICustomField;
  onChange: Function;
  control: Control;
  errors: any;
}

export default function CustomFieldRating({ customField, onChange, control, errors }: CustomFieldRatingProps) {
  const [val, setVal] = useState<number>(0);
  const [maxRating, setMaxRating] = useState<number>(0)

  useEffect(() => {
    setVal(0);
    onChange(customField, 0);
  }, []);

  useEffect(() => {
    if (customField.options) {
      setMaxRating(parseInt(customField.options[0].value as string))
    }
  }, [customField]);

  const updateQuestion = (newVal) => {
    setVal(newVal);
    onChange(customField, newVal);
  };

  return (
    <Controller
      name={customField.id}
      control={control}
      defaultValue={0}
      rules={{
        required: customField.isMandatory
      }}
      render={({ field }) => (
        <>
          <div>
            {fetchTranslation(customField.name)}
            {customField.isMandatory && <> *</>}
          </div>
          <FormControl
            variant="outlined"
            key={customField.id}
            fullWidth
            margin="dense"
            error={Boolean(errors[customField.id])}
          >
            <Rating
              emptyIcon={
                <StarOutlineIcon
                  sx={{
                    color: 'black'
                  }}
                  fontSize="inherit"
                />
              }
              name={field.name}
              value={val}
              size="large"
              max={maxRating}
              onChange={(event, newValue) => {
                if (newValue !== null) {
                  setVal(newValue);
                  field.onChange(newValue);
                  updateQuestion(newValue);
                }
              }}
            />
          </FormControl>
          {errors[customField.id] && (
            <FormHelperText error>
              {errors[customField.id]?.message}
            </FormHelperText>
          )}
        </>
      )}
    />
  );
}
