import { ReactJSXElement } from "@emotion/react/types/jsx-namespace"
import ErrorBoundary from "../elements/ErrorBoundary"
import { ICustomField } from "../product/interfaces"
import CustomFieldCheckbox from './customFieldCheckbox';
import CustomFieldDropdown from './customFieldDropdown';
import CustomFieldText from './customFieldText';
import { Control, FieldErrors } from "react-hook-form";
import CustomFieldRating from "./customFieldRating";
import CustomFieldImageUpload from "./customFieldUploadImage";
import * as yup from 'yup';
import { fetchTranslation, getCurrentLanguageFromLocalStorage } from "../../utils/languageTranslation.util";
import CustomFieldVideoShow from "./customFieldVideoShow";
import CustomFieldTextInfo from "./customFieldTextInfo";

export const generateCustomFieldControl = (field: ICustomField, control: Control, handleCustomFieldChange: Function, errors: FieldErrors): ReactJSXElement | undefined => {
    if (field.type === 'CHECKBOX') {
        return <CustomFieldCheckbox control={control} customField={field} onChange={handleCustomFieldChange} errors={errors} />;
    } else if (field.type === 'DROPDOWN') {
        return <CustomFieldDropdown customField={field} control={control} onChange={handleCustomFieldChange} errors={errors} />;
    } else if (field.type === 'TEXT') {
        return <CustomFieldText control={control} customField={field} handleChange={handleCustomFieldChange} errors={errors} />;
    }
    else if (field.type === 'RATING') {
        return (<CustomFieldRating control={control} customField={field} onChange={handleCustomFieldChange} errors={errors} />)
    }
    else if (field.type === 'IMAGE_UPLOAD') {
        return (<CustomFieldImageUpload control={control} customField={field} onChange={handleCustomFieldChange} errors={errors} />)
    }
    else if (field.type === 'VIDEO_SHOW') {
        return (<CustomFieldVideoShow control={control} customField={field} onChange={handleCustomFieldChange} errors={errors} />)
    }
    else if (field.type === 'TEXT_INFO') {
        return (<CustomFieldTextInfo control={control} customField={field} onChange={handleCustomFieldChange} errors={errors} />)
    }
    return undefined;
}

export const generateCustomFieldValidationSchema = (customFields: ICustomField[] | undefined, t: Function, schemaFields?: any) => {
    if (!schemaFields || schemaFields === undefined || schemaFields === null) {
        schemaFields = {};
    }
    customFields?.forEach(c => {
        if (c.isMandatory) {
            if (c.type === 'CHECKBOX' || (c.type === 'VIDEO_SHOW' && c.options?.find(o => o.id === 'confirmationMethod')?.value === 'CHECKBOX')) {
                schemaFields[c.id] = yup
                    .boolean()
                    .oneOf([true], `${fetchTranslation(c.name)} ${t('translation.cartStep.genericIsRequired')}`);
            } else if (c.type === 'RATING') {
                schemaFields[c.id] = yup
                    .number()
                    .oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10], `${fetchTranslation(c.name)} ${t('translation.cartStep.genericIsRequired')}`);
            }
            else {
                schemaFields[c.id] = yup
                    .string()
                    .required(`${fetchTranslation(c.name)} ${t('translation.cartStep.genericIsRequired')}`);
            }
        }
    });

    return yup.object().shape(schemaFields);
}