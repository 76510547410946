import React, { useEffect, useState } from 'react';
import { Checkbox, FormControl, FormControlLabel, FormHelperText, OutlinedInput, Stack, Typography } from '@mui/material';
import { ICustomField } from '../product/interfaces';
import { fetchTranslation, getCurrentLanguageFromLocalStorage } from '../../utils/languageTranslation.util';
import { Control, Controller } from 'react-hook-form';

interface CustomFieldTextProps {
  customField: ICustomField;
  handleChange: Function;
  errors: any;
  control: Control<any>;
}

export default function CustomFieldText({ customField, handleChange, errors, control }: CustomFieldTextProps) {
  const placeholder = fetchTranslation(customField.name);

  useEffect(() => {
    handleChange(customField, '');
  }, []);

  return (
    <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
      <Controller
        name={customField.id}
        control={control}
        defaultValue=''
        rules={{
          required: customField.isMandatory
        }}
        render={({ field }) => (
          <FormControl
            variant="outlined"
            key={customField.id}
            fullWidth
            margin="dense"
            error={Boolean(errors[customField.id])}
          >
            <OutlinedInput
              size="small"
              placeholder={placeholder}
              sx={{ borderRadius: 1 }}
              // Spread the Controller's `field` to wire up the value & onChange
              {...field}
              error={Boolean(errors[customField.id])}
            />
            {errors[customField.id] && (
              <FormHelperText error>
                {errors[customField.id]?.message}
              </FormHelperText>
            )}
          </FormControl>
        )}
      />
    </Stack>
  );
}
