import { useMemo } from 'react';
import { Box, Stack, Grid, IconButton, Typography } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { PromoCodeV2Alpha2 } from './../product/interfaces';
import { getCurrentLanguageFromLocalStorage, translate } from '../../utils/languageTranslation.util';

interface Props {
    setSelectedPromoCodes?: (promocode: PromoCodeV2Alpha2[]) => void;
    disableEdit?: boolean;
    currencyISO: string;
    backToProducts?: () => void;
    selectedPromoCodes: PromoCodeV2Alpha2[];
}

interface GroupedPromoCodes {
    [key: number]: PromoCodeV2Alpha2 & { quantity: number };
}

const PromoSummary = ({ setSelectedPromoCodes, disableEdit = false, currencyISO, backToProducts, selectedPromoCodes }: Props) => {
    const groupedPromoCodes: GroupedPromoCodes = useMemo(() => {
        return selectedPromoCodes.reduce((acc, promoCode) => {
            // Ensure that selectedValue is a string that can be concatenated to form the key.
            const key = `${promoCode.id}_${promoCode.selectedProductValue}`;
            // If this specific id and selectedValue combination already exists, increment quantity
            if (acc[key]) {
                acc[key].quantity++;
            } else {
                // Otherwise, add the promoCode with quantity 1
                acc[key] = { ...promoCode, quantity: 1 };
            }
            return acc;
        }, {});
    }, [selectedPromoCodes]);

    const removeProduct = (promocode: PromoCodeV2Alpha2) => {
        const selectedPromoCodesReturn = [...selectedPromoCodes];
        const objIndex = selectedPromoCodesReturn.findIndex(
            (selectedPromo) => selectedPromo.id === promocode.id && selectedPromo.selectedProductValue === promocode.selectedProductValue
        );

        if (objIndex >= 0) {
            selectedPromoCodesReturn.splice(objIndex, 1);
        }

        if (setSelectedPromoCodes) {
            setSelectedPromoCodes(selectedPromoCodesReturn);
        }
    };

    return (
        <Stack spacing={1} sx={{ px: 0, py: 1 }}>
            <Grid container spacing={0}>
                {Object.values(groupedPromoCodes).map((promocode) => (
                    <Grid
                        container
                        item
                        spacing={0}
                        sx={{
                            alignItems: 'center',
                            borderBottom: '1px solid rgba(0, 0, 0, 0.23)',
                            pb: 1,
                            pt: 1,
                            position: 'relative'
                        }}
                        key={`${promocode.id}_${promocode.selectedProductValue}`}>
                        {!disableEdit && (
                            <Grid item xs={1}>
                                <IconButton onClick={() => removeProduct(promocode)} size="small">
                                    <DeleteOutlineIcon />
                                </IconButton>
                            </Grid>
                        )}
                        <>
                            <Grid item xs={2} sx={{ textAlign: 'center' }}>
                                <Box
                                    sx={{
                                        alignSelf: 'center',
                                        height: '60px',
                                        width: '60px',
                                        textAlign: 'center',
                                        display: 'flex'
                                    }}>
                                    <img
                                        alt="promocode"
                                        src={promocode.imageUrl}
                                        style={{ height: 60, width: 60, margin: 'auto', objectFit: 'cover' }}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={disableEdit ? 7 : 5} sx={{ pl: 1, pr: 1, maxWidth: '30% !important', minWidth: '50% !important' }}>
                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                    {translate(promocode.name, getCurrentLanguageFromLocalStorage())}
                                </Typography>
                                <Typography variant="body2" sx={{ fontSize: '12px' }}>
                                    {translate(promocode.description, getCurrentLanguageFromLocalStorage())}
                                </Typography>
                                {promocode.type === 2 && (
                                    <Typography
                                        sx={{
                                            maxHeight: 40,
                                            fontSize: '12px'
                                        }}
                                        variant="body2">
                                        {promocode.productVariants.find((variant) => variant.price === promocode.selectedProductValue)?.worth}ggr
                                    </Typography>
                                )}
                                {promocode.type === 1 && (
                                    <Typography
                                        sx={{
                                            maxHeight: 40,
                                            fontSize: '12px'
                                        }}
                                        variant="body2">
                                        {promocode.productVariants.find((variant) => variant.price === promocode.selectedProductValue)?.price}
                                        {currencyISO}
                                    </Typography>
                                )}
                            </Grid>
                            {/* <Grid item xs={disableEdit ? 1 : 2}>
                                <FormControl sx={{ minWidth: 28 }}>
                                    <Typography
                                        sx={{
                                            borderRadius: 20,
                                            maxHeight: 40,
                                            border: 'solid 1px #004E31',
                                            textAlign: 'center'
                                        }}
                                        variant="body1">
                                        {promocode.quantity}
                                    </Typography>
                                </FormControl>
                            </Grid> */}
                            <Grid
                                item
                                xs={2}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    textAlign: 'right',
                                    right: 0,
                                    position: 'absolute',
                                    maxWidth: '100% !important',
                                    minWidth: '30% !important'
                                }}>
                                <Typography
                                    sx={{
                                        borderRadius: 20,
                                        maxHeight: 40,
                                        border: 'solid 1px',
                                        borderColor: 'primary',
                                        textAlign: 'center',
                                        minWidth: 35,
                                        marginRight: '8px',
                                        padding: '4px'
                                    }}
                                    variant="body1">
                                    {promocode.quantity}
                                </Typography>
                                <Box sx={{ width: '100%' }}>
                                    <Typography variant="body2">
                                        {parseFloat(promocode.selectedProductValue!) * promocode.quantity} {currencyISO}
                                    </Typography>
                                </Box>
                            </Grid>
                        </>
                    </Grid>
                ))}
            </Grid>
        </Stack>
    );
};

export default PromoSummary;
